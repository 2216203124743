<h5 style="margin:1% 0%"> FAQ </h5>
<hr>
<table mat-table [dataSource]="dataSource" style="width: 100%">
  <ng-container matColumnDef="screenName">
    <th mat-header-cell *matHeaderCellDef>Screen Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">
      {{ element.is_active }}
    </td>
  </ng-container>

  <ng-container matColumnDef="Language">
    <th mat-header-cell *matHeaderCellDef>Language</th>
    <td mat-cell *matCellDef="let element">
      {{ element.language }}
    </td>
  </ng-container>


  <ng-container matColumnDef="questions">
    <th mat-header-cell *matHeaderCellDef>Questions</th>
    <td mat-cell *matCellDef="let element">
      {{ element.question_answers.length }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actionsColumn">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let element">
      <i
        class="fa fa-edit"
        [routerLink]="['/v2/product/content/faq/edit/', element.name]"
        (click)="edit(element)"
        [queryParams]="{ language: element.language, name: element.name }"
      >
      </i>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator
    [pageSizeOptions]="[10, 25, 50, 100,200]"
      showFirstLastButtons
    ></mat-paginator>
<div class="fixed-button active">
  <a routerLink="/v2/product/content/faq" class="btn-md btn-theme has-ripple mr-2">
   Back
  </a>
    <a routerLink="/v2/product/content/faq/add" class="btn-md btn-theme has-ripple mr-2">
      + FAQ
    </a>
    </div>