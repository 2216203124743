import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { FaqComponent } from './faq.component';
import { AddFaqComponent } from './faq/add-faq/add-faq.component';
import { DemoMaterialModule } from 'src/app/demomodule';
import { FAQService } from 'src/app/service/support-admin/faq.service';
import { AddScreenComponent } from './screennames/add-screen/add-screen.component';
import { ViewScreenComponent } from './screennames/view-screen/view-screen.component';
import { AddTermsComponent } from './terms_conditions/add-terms/add-terms.component';
import { ViewTermsComponent } from './terms_conditions/view-terms/view-terms.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { QuillModule } from 'ngx-quill';
import { ViewFaqComponent } from './faq/view-faq/view-faq.component';
import { EditorModule } from '@tinymce/tinymce-angular';

const routes: Routes = [
  {
    path: '',
    component: FaqComponent,
  },
  {
    path:'add',
    component:AddFaqComponent
  },
  {
    path:'view-faq',
    component:ViewFaqComponent
  },
  {
    path:'edit/:id',
    component:AddFaqComponent
  },
  {
    path:'add-screen',
    component:AddScreenComponent
  },
  {
    path:'edit-screen/:id',
    component:AddScreenComponent
  },
  {
    path:'view-screen',
    component:ViewScreenComponent
  },
  {
    path:'view-terms',
    component:ViewTermsComponent
  },
  {
    path:'add-terms',
    component:AddTermsComponent
  },
  {
    path:'edit-terms/:id',
    component:AddTermsComponent
  },

];

@NgModule({
  declarations: [FaqComponent, AddFaqComponent, AddScreenComponent, ViewScreenComponent, AddTermsComponent, ViewTermsComponent, ViewFaqComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    MatExpansionModule,
    DemoMaterialModule,
    AngularEditorModule ,
QuillModule.forRoot(),
EditorModule,
    MaterialModule
  ], providers: [FAQService]
})
export class FaqModule { }
