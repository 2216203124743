import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { FAQService } from "src/app/service/support-admin/faq.service";
import { VipContactsService } from "src/app/service/vip-contacts.service";
declare var swal: any;

@Component({
  selector: "app-vip",
  styleUrls: ["./vip.component.scss"],
  templateUrl: "./vip.component.html",
})
export class VipComponent implements OnInit {
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  currentPage: any = 1;

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    "Name",
    "mobile",
    "whatsapp",
    "phone",
    "level",
    "languages",
    "actionsColumn",
  ];

  constructor(private vipservice: VipContactsService) {}
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  ngOnInit() {
    this.getVipList();
  }

  getVipList() {
    const params: any = { page: this.currentPage };

    this.vipservice.getVipContactsList(params).subscribe(
      (res) => {
      this.dataSource.data = res.data.data;
    },
    error => {
      swal.fire({
        title: "Error",
        text: error,
        icon: "error",
      });
    }
    );
  }

  deleteVIP(id) {
    this.vipservice.deleteVipContacts(id).subscribe(
      (res) => {
      if (res["success"] == 1) {
        swal.fire({
          title: "Success",
          text: "VIP Deleted Successfully",
          icon: "success",
        });
        this.getVipList();
      } else {
        swal.fire({
          title: "Error",
          text: res["error"][0],
          icon: "error",
        });
      }
    },
    error => {
      swal.fire({
        title: "Error",
        text: error,
        icon: "error",
      });
    });
  }
}
