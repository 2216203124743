
export class VIP{
    name:string=''
    email:string=''
    phone_number:string='+91'
    whatsapp_number:string='+91'
    assigned_user_level: Array<any> = [];
    languages:Array<any> = [];
}


    