import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class VipContactsService {
  API_URL: string = environment.APIExpressEndpoint;

  constructor(
    private http: HttpClient,
  ) { }

  getVipContactsList(param): Observable<any> {
    return this.http.get(`${this.API_URL}/v2/support/vip_host`, { params: param });
  }

  addVipContacts(data): Observable<any> {
    return this.http.post(`${this.API_URL}/v2/support/vip_host/`, data);
  }

  editVipContacts(id, data): Observable<any> {
    return this.http.put(`${this.API_URL}/v2/support/vip_host/${id}/`, data);
  }

  getVipContactsById(id, param = null): Observable<any> {
    return this.http.get(`${this.API_URL}/v2/support/vip_host/${id}/`, { params: param });
  }

  deleteVipContacts(id): Observable<any> {
    return this.http.delete(`${this.API_URL}/v2/support/vip_host/${id}/`);
  }
}
