<div class="row mb-4">
  <div class="col-md-12 mb-4">
      <div class="card text-left">

          <div class="card-body">
              <!--<h4 class="card-title mb-3">Zero configuration</h4>
              <p>DataTables has most features enabled by default, so all you need to do to use it with your own ables is to call the construction function: $().DataTable();.</p>-->
              <div class="table-responsive1" *ngIf="loadingState==false;else loadingData">
                <div fxLayout fxLayoutAlign="center right">

                  <mat-form-field fxFlex="40%">
                    <input matInput type="text" #c3 (keyup)="doFilter($event.target.value)" placeholder="Search">
                  </mat-form-field>
                  <!-- <button style="margin-left: 5px;" color="warn" mat-raised-button  (click)="clearFilters();" class="three">Reset</button> -->


                </div>
                  <mat-table #table [dataSource]="dataSource"  matSort class="mat-elevation-z81" hiddenColumns="[4]" >

                      <ng-container matColumnDef="#">
                        <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
                        <mat-cell *matCellDef="let element; index as i"> {{paginator.pageSize * paginator.pageIndex + i + 1}} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="displayName">
                        <mat-header-cell  *matHeaderCellDef>Name </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.displayName}} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="email">
                        <mat-header-cell  *matHeaderCellDef>Email </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="role">
                        <mat-header-cell  *matHeaderCellDef> Role</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{roles_text(element.role)}}
                         </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="last_login">
                        <mat-header-cell  *matHeaderCellDef> Last Login</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.last_login  | date: "MMM d, y, h:mm a"}} </mat-cell>
                      </ng-container>


                      <ng-container matColumnDef="created_date">
                        <mat-header-cell  *matHeaderCellDef>Created at </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.created_date  | date: "MMM d, y, h:mm a"}} </mat-cell>
                      </ng-container>

                      <!-- <ng-container matColumnDef="updated_date">
                        <mat-header-cell  *matHeaderCellDef> Rake%</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.updated_date}} </mat-cell>
                      </ng-container> -->

                      <ng-container matColumnDef="status">
                       <mat-header-cell  *matHeaderCellDef> Active</mat-header-cell>
                       <mat-cell *matCellDef="let element"> {{element.status==true?'Yes':'No' }} </mat-cell>
                     </ng-container>

                      <ng-container matColumnDef="actionsColumn">
                        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let element">          
                 <a type="submit" class="text text-danger" data-toggle="tooltip" data-placement="top" title="Edit" (click)="editPost(element,mymodal)">Edit</a>
                </mat-cell>
                      </ng-container>
                    
                      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                                               
              </div>
<mat-paginator [pageSizeOptions]="[10, 25, 50, 100,200]" showFirstLastButtons></mat-paginator>

              <ng-template #loadingData>
                 <div id="waitforData">
                      <div class="spinner spinner-primary mr-3"></div>
                      <h4 class="example-h2">Loading ,,,,</h4>
                    </div>
                </ng-template>
          </div>
      </div>
  </div>
</div>
  <!-- end of col -->

                   <ng-template #mymodal let-modal>
                    <!-- <div class="modal fade" id="post-modal" aria-hidden="true"> -->
                      <!-- <div class="modal" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content"> -->
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title"> {{addForm.value._id==null?'New':'Edit'}} Admin User </h4>
                    </div>
                    <div class="modal-body">
                      <form [formGroup]="addForm" enctype="multipart/form-data" >

                        <div class="form-group">
                          <label>Name <em>*</em></label>
                          <input type='text' formControlName='displayName' class="form-control" placeholder="Name">
                         </div>

                      <div class="form-group">
                       <label>Email <em>*</em></label>
                       <input type='email' formControlName='email' class="form-control" placeholder="Email ID" [readonly]="addForm.value._id!=null?true:false">
                       <input type='hidden' formControlName='_id'>
                      </div>
                     

                       <div class="form-group">
                         <label>Role <em></em></label>
                         <ng-select number
                         formControlName='role'
                         [items]="rolesArray"
                         bindLabel="label"
                         bindValue="value"
                         placeholder="Select Role"
                         [searchable]="true"
                         [readonly]="false"
                         [multiple]="true"
                         [closeOnSelect]="true"
                       >
                       <!-- <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                         <input id="item-{{index}}" type="checkbox" [ngModelOptions]="{standalone: true}" name="item-{{index}}" [ngModel]="item$.selected" /> {{item.label}}
                     </ng-template> -->
                       </ng-select>
                     
                         </div>

                              <div class="form-group form-check">
                                <input type="checkbox"
                              formControlName="status"  class="form-check-input" />
                              <label class="form-check-label" for="status" >Active </label>
                            </div>
                          
                       </form>
                              </div>

                              <div class="modal-footer">
                            
                           
                                <button type="submit" class="btn btn-warning" data-toggle="tooltip" data-placement="top" title="Cancel" (click)="closePost()">Close</button>

                                <button type="submit" class="btn btn-success" data-toggle="tooltip" data-placement="top" title="Submit" *ngIf="loadingState==false" (click)="createOrUpdatePost(addForm)">Submit </button>
                                <div *ngIf="loadingState==true">Submitting,,,,,</div>

                                  </div>
                          <!-- </div>
                        </div>
                      </div>         -->
                      </ng-template>      



                      <div class="fixed-button active">
                        <a type="submit" class="btn-md btn-theme has-ripple" (click)="createPost(mymodal)">
                          <i class="fa fa-plus" aria-hidden="true"></i>
                          New
                        </a>
                      </div>
                      
 