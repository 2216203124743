export const urlRegex = "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)";
export const commonData = {

    CAMPAIGN_TYPES: [
        { value: 'sign_up', label: 'Sign Up' },
        { value: 'referral', label: 'Referral' },
        { value: 'acb', label: 'ACB' },
        { value: 'vip_booster', label: 'VIP Booster' },
        { value: 'reward_points', label: 'Reward Points' },
        { value: 'purchase', label: 'Purchase' },
        { value: 'support', label: 'Support' },
        { value: 'banner', label: 'Banner' },
    ],

    REFERRAL_CODE_TYPE_CHOICES: [
        { value: 'special', label: 'Special' },
        { value: 'user_linked', label: 'User Linked' },
        { value: 'normal', label: 'Normal' },
    ],
    PURCHASE_BONUS_TAB_TYPES: [
        { value: 'welcome', label: 'Welcome' },
        { value: 'club', label: 'Club' },
        { value: 'daily', label: 'Daily' },
        { value: 'festive', label: 'Festive' },
        { value: 'special', label: 'Special' }
    ],

    WELCOME_COUPON_TYPE_CHOICES : [
        {value : "normal_first", label : "Normal first"},
        {value : "vip_first", label : "Vip first"},
        {value : "timebound_first",label : "Timebound first"},
        {value : "normal_second", label : "Normal second"},
        {value : "normal_third", label : "Normal third"}
    ],

    DAILY_COUPON_TYPE_CHOICES : [
        {value : "surprise", label : "Surprise"},
        {value : "happy_hours", label : "Happy_Hours"},
        {value : "powerplay",label : "Powerplay"},
    ],

    CLUB_COUPON_TYPE_CHOICES : [
               { value : 0, label : "IRON"},
               { value : 1, label : "BRONZE"},
               { value : 2, label : "SILVER"},
               { value : 3, label : "GOLD"},
               { value : 4, label : "PLATINUM"},
               { value : 5, label : "VIP CLASSIC"},
               { value : 6, label : "VIP PREMIUM"},
               { value : 7, label : "VIP ROYALE"}
            ],

    USER_SEGMENTATION_TYPE: [
        { value: 'user_level', label: 'User Club Level' },
        { value: 'user_segment', label: 'User Segments' },
        { value: 'user_list', label: 'User List' }
    ],

    ELIGIBILITY_USER_SEGMENTS:[
        { value: 'lt_deposit_count_zero_and_no_referral_code', label: 'Normal' },
        { value: 'lt_deposit_count_zero_and_referral_code', label: 'Referral' },
        { value: 'lt_deposit_count_zero_and_special_referral_code', label: 'Coupon' },
    ],

    //FOR SIGNUP BONUS ADD - DATE - 08-01-2021- RAVI
    PLATFORMS : [
        {value: "rmg",  label:"Real Money App"},
        {value: "playstore", label:"Play Store App"}
    ],


    //FOR PURCHASE BONUS ADD - DATE - 12-01-2021- RAVI
    ELIGIBILITY_USER_LEVELS : [
        {value: 0,  label:"0"},
        {value: 1,  label:"1"},
        {value: 2,  label:"2"},
        {value: 3,  label:"3"},
        {value: 4,  label:"4"},
        {value: 5,  label:"5"},
        {value: 6,  label:"6"},
        {value: 7,  label:"7"},
    ],

     //FOR VALID FROM EVENTS - PURCHASE BONUS
     VALID_FROM_EVENTS : [
        {value: 'signup',  label:"Sign up"}
    ],

       //FOR BET VARIANTS - BET CONFIG RUMMY
       BET_VARIANTS : [
        {value: 'Points',  label:"Points Rummy"},
        {value: '201 Pool',  label:"201 Pool"},
        {value: '101 Pool',  label:"101 Pool"},
        {value: 'Bo2',  label:"Best of 2"},
        {value: 'Bo3',  label:"Best of 3"},
        //{value: 'callBreak',  label:"CallBreak"},
        //{value: 'poker',  label:"Poker"}
    ],

     //FOR BET VARIANTS LIST - BET CONFIG RUMMY
     BET_VARIANTS_LIST : {
      'Points':"Points Rummy",
      '201 Pool':"201 Pool",
      '101 Pool':"101 Pool",
      'Bo2':"Best of 2",
      'Bo3':"Best of 2",
      'callBreak':"CallBreak",
      'poker':"Poker",
     },

     //FOR BET VARIANTS FRO GT- BET CONFIG RUMMY
     BET_VARIANTS_FOR_GT : {
      'Points':"Points Rummy",
      '201 Pool':"Pool Rummy",
      '101 Pool':"Pool Rummy",
      'Bo2':"Deal Rummy",
      'Bo3':"Deal Rummy",
      'callBreak':"CallBreak",
      'poker':"Poker",
  },

     //FOR BET LEVELS
     BET_LEVELS : [
        {value: "L0",  label:"L0"},
        {value: "L1",  label:"L1"},
        {value: "L2",  label:"L2"},
        {value: "L3",  label:"L3"},
        {value: "L4",  label:"L4"},
        {value: "L5",  label:"L5"},
        {value: "L6",  label:"L6"},
        {value: "L7",  label:"L7"},
    ],

      //FOR ROLES - ADMIN USER CONFIG
      ROLES : [
        {value: 'Promotions',  label:"Promotions"},
        {value: 'Product',  label:"Product"},
        {value: 'Support',  label:"Support"},
        {value: 'Finance',  label:"Finance"},
        {value: 'Admin',  label:"Admin"},
    ],
    
     Role : {
        "Promotions" : 'Promotions',
        "Admin" : 'Admin'
    },
    
  //FOR GAME TYPES - PURCHASE BONUS
  GAME_TYPES : [
    {value: 'rummy',  label:"Rummy"},
    {value: 'callbreak',  label:"CallBreak"},
    {value: 'poker',  label:"Poker"},
  ],


     //FOR GAME VARIANTS LIST - CHALLENGE
    GAME_VARIANTS : [
        {value: 'points_2p',  label:"Points 2P"},
        {value: 'points_6p',  label:"Points 6P"},
        {value: '101_pool_2p',  label:"101 Pool 2P"},
        {value: '101_pool_6p',  label:"101 Pool 6P"},
        {value: '201_pool_2p',  label:"201 Pool 2P"},
        {value: '201_pool_6p',  label:"201 Pool 6P"},
        {value: 'Bo2',  label:"Best of 2"},
        {value: 'Bo3',  label:"Best of 3"},
     ],
     
  default_date : new Date(new Date().getTime() + (330 * 60 * 1000)).toISOString().slice(0,16),

  //FOR LEADING SCREEN - BANNER
  LEADING_SCREEN : [
    {value: 'add_cash',  label:"Add cash"},
    {value: 'wallet',  label:"wallet"},
    {value: 'referrals',  label:"Referrals"},
    {value: 'challenges',  label:"Challenges"},
    {value: 'cash_games',  label:"Cash Games"},
    {value: 'reward_points_page',  label:"Reward Points Page"},
    {value: 'loyalty_page',  label:"Loyalty Page"},
  ],

    //FOR VISIBLE SCREEN - BANNER
    VISIBLE_SCREEN : [
        {value: 'intro_screen',  label:"Intro Screen"},
        {value: 'lobby_home',  label:"Lobby Home"},
    ],    


}
export class CommonFunction {
    public static changedateFormate = (date) => {
        let today;
        if (date) { today = date } else { today = new Date(); }
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        var hrs = today.getHours();
        var mins = today.getMinutes();

        // 2020-02-04 00:00:00
        let finaldate = `${yyyy}-${mm}-${dd} ${hrs}:${mins}:00`;
        return finaldate;
    }
    public static format_utc_date = (date) => {
        let today;
        if (date) { today = date } else { today = new Date(); }
    let utc_datetime = new Date(date).toUTCString();
    return new Date(utc_datetime).toISOString();
    }
    public static format_utc_to_ind_date = (date) => {
        let today;
        if (date) { today = date } else { today = new Date(); }
        var to_ind_time = new Date(today);
        to_ind_time.setMinutes(to_ind_time.getMinutes() + 330);
        today = to_ind_time.toISOString();
        let finaldate = today.toLocaleString("en-US", {timeZone: "Asia/Kolkata"}).slice(0, 16);
    return finaldate;
    }
  
    public static format_date = (date) => {
        let today;
        if (date) { today = date } else { today = new Date(); }
        var to_ind_time = new Date(today);
        to_ind_time.setMinutes(to_ind_time.getMinutes() + 330);
        today = to_ind_time.toISOString();
        let finaldate = today.toLocaleString("en-US", {timeZone: "Asia/Kolkata"}).slice(0, 16);
         return finaldate;
    }

    public static format_date_normal = (date) => {
        let today;
        if (date) { today = date } else { today = new Date(); }
        var to_ind_time = new Date(today);
       // to_ind_time.setMinutes(to_ind_time.getMinutes() + 330);
        today = to_ind_time.toISOString();
        let finaldate = today.toLocaleString("en-US", {timeZone: "Asia/Kolkata"}).slice(0, 16);
         return finaldate;
    }

   public format_date_view(date) {
        let today;
        if (date) { today = date } else { today = new Date(); }
        var to_ind_time = new Date(today);
        to_ind_time.setMinutes(to_ind_time.getMinutes() + 330);
        today = to_ind_time.toISOString();
        let finaldate = today.toLocaleString("en-US", {timeZone: "Asia/Kolkata"}).slice(0, 16);
         return finaldate;
      }

      public static total_otc_percent(a,b,c,d){
          let total = 0;
          total = a + b + c + d;
          return total;
      }

      public static releaseRatio (a,b,c,d){
        if(a>0 || b>0 || c>0 ||d>0){
            return true;
        }
        else return false
    }
}

