<perfect-scrollbar style="top: 40px;"
*ngIf="this.dattaConfig['layout'] === 'vertical'" [exclude]="'#mobile-collapse1'"
  (clickOutside)="navMob()" id="nav-ps-datta" ngClass="datta-scroll" [style.max-width]="'300px'"
  [style.max-height]="'calc(100vh - 70px)'"
   [usePSClass]="'datta'" [disabled]="null" [autoPropagation]="true">
  <div class="navbar-content">
    <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()" (mouseleave)="navMob()">
      <ng-container *ngFor="let item of navigation">
        <div class="header">
          <p> {{item.title}} </p>
          <li *ngFor="let item of item.children" >
            <a class="nav-link" [routerLinkActive]="['active']"
             [target]="item.target ? '_blank' : '_self'" [routerLink]="[item.url]"
              (click)="closeOtherMenu($event)">
              <i class="feather" [ngClass]="item.icon" style="margin-right: 10px;"></i>
              <span class="pcoded-mtext" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
              <ng-template #directTitle>
                {{item.title}}
              </ng-template>
            </a>
          </li>
          <!-- <a routerLink="/v2/promo/dashboard/default"
          routerLinkActive='active' class="btn itemmenu active">
            <i class="feather icon-home"> </i>Dashboard</a> -->
        </div>
      </ng-container>
    </ul>
  </div>
</perfect-scrollbar>
<div *ngIf="this.dattaConfig['layout'] === 'horizontal'" class="navbar-content sidenav-horizontal" id="layout-sidenav">
  <a href="javascript:" class="sidenav-horizontal-prev" [ngClass]="prevDisabled" (click)="scrollMinus()"></a>
  <div #navbarWrapper class="sidenav-horizontal-wrapper">
    <!-- add 14-4 viral -->
    <ul #navbarContent id="side-nav-horizontal" class="nav pcoded-inner-navbar sidenav-inner"
      (clickOutside)="fireLeave()" (mouseleave)="fireLeave()">
      <ng-container *ngFor="let item of navigation">
        <div class="header">
          <p> {{item.title}} </p>
          <li *ngFor="let item of item.children" [routerLinkActive]="['active']">
            <a class="nav-link"
             [target]="item.target ? '_blank' : '_self'" [routerLink]="[item.url]"
              (click)="closeOtherMenu($event)">
              <i class="feather" [ngClass]="item.icon" style="margin-right: 10px;"></i>
              <span class="pcoded-mtext" *ngIf="item.icon; else directTitle">{{ item.title }}</span>
              <ng-template #directTitle>
                {{item.title}}
              </ng-template>
            </a>
          </li>
          <!-- <a routerLink="/v2/promo/dashboard/default"
          routerLinkActive='active' class="btn itemmenu active">
            <i class="feather icon-home"> </i>Dashboard</a> -->
        </div>
      </ng-container>
    </ul>
  </div>
  <a href="javascript:" class="sidenav-horizontal-next" [ngClass]="nextDisabled" (click)="scrollPlus()"></a>
</div>