export class termsData {
    language: string;
    screen_name: string;
    termsAndConditions: Array<any> = [
      {
        content: "",
        priority: 0,
        url:"",
      },
    ];
  }
  