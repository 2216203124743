<div class="row mt-4">
    <div class="col-md-6">
        <h5>Add Screen</h5>

    </div>
    <!-- <div class="col-md-6 text-right">
        <a  routerLink="/faq/view-screen" class="viewscreen">View Screens</a>
    </div> -->

</div>
<hr />
<div class="addscreen">
    <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" id="name"
                           required
                           [(ngModel)]="screenname" name="name"
                           #name="ngModel">
                    <div *ngIf="heroForm.submitted && !name.valid"
                         class="alert alert-danger">
                      Name is required
                    </div>
                  </div>
            </div>
           </div>
        <div class="mt-4">
        <button type="submit" class="btn btn-success">Submit</button>
        <button type="button" class="btn btn-danger" routerLink="/v2/product/content/faq/view-screen">Cancel</button>

</div>
        </form>
   
    </div>
