import { NgModule } from '@angular/core';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

import { A11yModule } from "@angular/cdk/a11y";
import { BidiModule } from "@angular/cdk/bidi";
import { ObserversModule } from "@angular/cdk/observers";
import { OverlayModule } from "@angular/cdk/overlay";
import { PlatformModule } from "@angular/cdk/platform";
import { PortalModule } from "@angular/cdk/portal";

import { CdkStepperModule } from "@angular/cdk/stepper";
import { CdkTableModule } from "@angular/cdk/table";

import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';

//import { MatSelectFilterModule } from 'mat-select-filter';

import {MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatSortModule} from '@angular/material/sort';

//import { MatTableExporterModule } from 'mat-table-exporter';

import { NgSelectModule } from '@ng-select/ng-select';

import { ClipboardModule } from '@angular/cdk/clipboard';

import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule, 
  NgxMatTimepickerModule 
} from '@angular-material-components/datetime-picker';

@NgModule({
  imports: [
    // CDK
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    CdkStepperModule,
    CdkTableModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatFormFieldModule,
    MatDividerModule,
    MatIconModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatSelectModule,
  //  MatTableExporterModule,
   // MatSelectFilterModule,
    NgSelectModule,
    ClipboardModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule


  ],
  exports: [
      // CDK
      A11yModule,
      BidiModule,
      ObserversModule,
      OverlayModule,
      PlatformModule,
      PortalModule,
      CdkStepperModule,
      CdkTableModule,
      MatTableModule,
      MatPaginatorModule,
      MatCardModule,
      MatFormFieldModule,
      MatDividerModule,
      MatIconModule,
      MatToolbarModule,
      MatInputModule,
      MatButtonModule,  
      MatDatepickerModule,
      MatNativeDateModule,
      MatSortModule,
      MatSelectModule,
      //MatTableExporterModule,
      //MatSelectFilterModule,
      NgSelectModule,
      ClipboardModule,
      NgxMatDatetimePickerModule,
      NgxMatTimepickerModule,
      NgxMatNativeDateModule
  

  ],
  declarations: [],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
  ]

})

export class MaterialModule {}