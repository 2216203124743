import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserWithdrawRequestTracksService } from 'src/app/service/support-admin/user-withdraw-request-tracks.service';

@Component({
  selector: 'app-view-withdrawal',
  templateUrl: './view-withdrawal.component.html',
  styleUrls: ['./view-withdrawal.component.scss']
})
export class ViewWithdrawalComponent implements OnInit {
  loadingState2: boolean;
  fraudreportArray: any[];
  panelOpenState:boolean
  constructor() { }

  ngOnInit(): void {

    this.fraudreportArray=  JSON.parse(localStorage.getItem("viewData"))
  }

   
}
