import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserWalletTrackService {

  API_URL: string = environment.APIExpressEndpoint;

  constructor(
    private http: HttpClient,
  ) { }

  getUserWalletTrackList(param): Observable<any> {
    return this.http.get(`${this.API_URL}/v2/support/user/wallet/`, { params: param });
  }

  getUserWalletTrackById(id, param): Observable<any> {
    return this.http.get(`${this.API_URL}/v2/support/user/wallet/${id}/`, { params: param });
  }

  // deleteUserWalletTrack(id): Observable<any> {
  //   return this.http.delete(`${this.API_URL}/user-wallet-track/${id}/`);
  // } 
}
