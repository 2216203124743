import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FAQService } from 'src/app/service/support-admin/faq.service';
declare var swal: any;

@Component({
  selector: 'app-add-screen',
  templateUrl: './add-screen.component.html',
  styleUrls: ['./add-screen.component.scss']
})
export class AddScreenComponent implements OnInit {
  edit_id: any;

  constructor(private faqservie:FAQService, private router:Router,private activatedRoute:ActivatedRoute) { }

  screenname:string
  ngOnInit(): void {

    console.log(this.activatedRoute.snapshot)
    this.edit_id=this.activatedRoute.snapshot.params.id

    if(this.edit_id){
      this.getScreenById(this.edit_id)
    }

  }

  onSubmit(){

    
    let data={
      is_active:true,
      is_deleted:false,
      name:this.screenname
    };

    if(this.edit_id){

    this.faqservie.updateScreenName(data,this.edit_id).subscribe(res=>{
      if (res["success"] == 1) {
        swal.fire({
          title: "Success",
          text: "Screen Updated Successfully",
          icon: "success",
        });
        this.router.navigateByUrl("/v2/product/content/faq/view-screen");
      } else {
        swal.fire({
          title: "Error",
          text: res["error"][0],
          icon: "error",
        });
      }
    })

    }
    else{

      this.faqservie.addScreeName(data).subscribe(res=>{
        if (res["success"] == 1) {
          swal.fire({
            title: "Success",
            text: "Screen Added Successfully",
            icon: "success",
          });
          this.router.navigateByUrl("/v2/product/content/faq/view-screen");
        } else {
          swal.fire({
            title: "Error",
            text: res["error"][0],
            icon: "error",
          });
        }
      })
  
    }

  }

  getScreenById(id){
    this.faqservie.editScreenName(id).subscribe(res=>{

      console.log(res['data'][0].name)
      this.screenname=res['data'][0].name
    })
  }
}
