import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FAQService } from 'src/app/service/support-admin/faq.service';
declare var swal:any

@Component({
  selector: 'app-view-faq',
  templateUrl: './view-faq.component.html',
  styleUrls: ['./view-faq.component.scss']
})
export class ViewFaqComponent implements OnInit {
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;
  languages = [
    { id: "en", name: "English" },
    { id: "ta", name: "TA" },
    { id: "fj", name: "FJ" },
  ];
  displayedColumns: string[] = [
    "screenName",
    "status",
    "questions",
    'Language',
    "actionsColumn",
  ];
  constructor(private faqservice:FAQService) { }

  ngOnInit(): void {
    this.getListofScreens()
  }

  getListofScreens(){
    this.faqservice.getScreensList().subscribe(res=>{
      console.log(res)
      let data = res.data.filter(res=>{
        return res.question_answers.length>0
      })
      this.dataSource.data=data
    }, error => {
      swal.fire({
        title: "Error",
        text: error,
        icon: "error",
      });
    })
  }
  edit(data){
    let saveobject = JSON.stringify(data)
    localStorage.setItem("questions",saveobject)
  }
}
