<div class="row" style="padding: 10px 0px">
  <div class="col-md-2">
    <select class="mt-2 dashboarddropdown">
      <option value="1" default>Daily</option>
      <option value="7">Weekly</option>
      <option value="30">Monthly</option>
    </select>
  </div>
</div>
<div class="row mb-4" style="padding: 10px 0px">
  <div class="col-md-2 mb-3">
    <div class="boxkyc">
      <!-- <h3>{{ count?.pending }}</h3> -->
      <p>Pending</p>
    </div>
  </div>
  <div class="col-md-2 mb-3">
    <div class="boxkyc">
      <!-- <h3>{{ count?.approved }}</h3> -->
      <p>Approved</p>
    </div>
  </div>
  <div class="col-md-2 mb-3">
    <div class="boxkyc">
      <!-- <h3>{{ count?.rejected}}</h3> -->
      <p>Rejected</p>
    </div>
  </div>
</div>

<i *ngIf="showLoader" class="fa fa-spinner fa-spin"></i>

<div class="row mb-4" style="padding: 10px 0px">
  <div class="col-md-6 mb-3 d-flex">
    <mat-form-field fxFlex="40%" class="mr-3">
      <input
        matInput
        type="text"
        #c3
        (change)="searchbyID($event.target.value)"
        placeholder="Search by ID"
      />
    </mat-form-field>

    <mat-form-field fxFlex="40%" class="mr-3">
      <input
        matInput
        type="date"
        #c1
        (change)="searchbyDate($event.target.value)"
        placeholder="From Date"
      />
    </mat-form-field>
    <div class="userlist" class="mr-3">
      <select
        class="mt-2"
        style="width: 100%"
        [(ngModel)]="searchStatus"
        (change)="searchbyStatus($event.target.value)"
      >
        <option value="Pending" default>Pending</option>
        <option value="Approved">Approved</option>
        <option value="Rejected">Rejected</option>
      </select>
    </div>
  </div>
  <div class="col-md-3" style="margin-top: 5px">
    <button
      style="margin-left: 5px"
      color="warn"
      mat-raised-button
      (click)="clearFilters()"
      class="three"
    >
      Reset
    </button>
  </div>
</div>

<div class="mat-elevation-z8 mt-4">
  <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef > User ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="unique_id">
      <th mat-header-cell *matHeaderCellDef >Unique ID</th>
      <td mat-cell *matCellDef="let element">{{ element.unique_id }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="beneId">
      <th mat-header-cell *matHeaderCellDef >Bene ID</th>
      <td mat-cell *matCellDef="let element">{{ element.beneId }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="transferId">
      <th mat-header-cell *matHeaderCellDef >Transfer Id</th>
      <td mat-cell *matCellDef="let element">{{ element.transferId }}</td>
    </ng-container>

    <ng-container matColumnDef="fraudreport">
      <th mat-header-cell *matHeaderCellDef >User Report</th>
      <td mat-cell *matCellDef="let element">
        <a
          target="_blank"
          [routerLink]="['/view-withdraw']"
          (click)="view(element.id)"
          [queryParams]="{ id: element.id }"
        >
          View
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="withdrawAmount">
      <th mat-header-cell *matHeaderCellDef >Withdraw Amount</th>
      <td mat-cell *matCellDef="let element">{{ element.withdrawAmount }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef >Status</th>
      <td mat-cell *matCellDef="let element">
        {{ element.status }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actionsColumn">
      <th mat-header-cell *matHeaderCellDef >Actions</th>
      <td mat-cell *matCellDef="let element">
        <a
          *ngIf="element.status == 'Pending'"
          class="text text-success"
          data-toggle="tooltip"
          data-placement="top"
          title="Accept"
          (click)="clickAccept(element.accept_url)"
          >Accept</a
        >
        <span
          *ngIf="element.status == 'Pending'"
          style="padding: 0px 5px 0px 5px"
        >
          |
        </span>
        <a
          *ngIf="element.status == 'Pending'"
          type="submit"
          class="text text-danger"
          data-toggle="tooltip"
          data-placement="top"
          title="Cancel"
          (click)="clickCancel(element.cancel_url)"
          >Reject</a
        >
        <span
          *ngIf="element.status == 'Pending'"
          style="padding: 0px 5px 0px 5px"
        >
        |
        </span>
        <a 
        class="text text-danger"
        data-toggle="modal" (click)="fraud(element.id)" data-target="#makefraud">
          Mark Fraud
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
  [pageSizeOptions]="[10, 25, 50, 100,200]"
    showFirstLastButtons
  ></mat-paginator>
</div>



<div class="modal" id="makefraud">
  <div class="modal-dialog">
    <div class="modal-content">
    
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Mark {{fraudid}} as Fraud </h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      
      <!-- Modal body -->
      <div class="modal-body">
        <div class="form-group">
          <label for="fraud_type"> Fraud Type <em>*</em></label>
          <input
            type="text"
            class="form-control"
            id="fraudtype"
            required
            [(ngModel)]="fraud_type"
            name="fraudtype"
            #fraudtype="ngModel"
          />
        </div>
        <div class="form-group">
          <label for="fraud_category"> Fraud Category <em>*</em></label>
          <input
            type="text"
            class="form-control"
            id="fraudcategory"
            required
            [(ngModel)]="fraud_category"
            name="fraudcategory"
            #fraudcategory="ngModel"
          />
        </div>
      </div>
      
      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="fraud(null)">Close</button>
        <button type="submit" class="btn btn-success" data-dismiss="modal" (click)="clickfraud()">Submit</button>
      </div>
      
    </div>
  </div>
</div>