import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavLogoComponent } from './theme/layout/admin/navigation/nav-logo/nav-logo.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import {NavigationItem} from './theme/layout/admin/navigation/navigation';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import {ToggleFullScreenDirective} from './theme/shared/full-screen/toggle-full-screen';
import {NgbButtonsModule, NgbDropdownModule,  NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import {MatExpansionModule} from '@angular/material/expansion';

import { UserWithdrawRequestTracksListModule } from './support-admin/user-withdraw-request-tracks/user-withdraw-request-tracks-list.module';
import { UserWalletTrackListModule } from './support-admin/user-wallet-track/user-wallet-track-list.module';

import { MaterialModule } from './material.module';
import { AdminUserConfigListModule } from './support-admin/admin-user-config/admin-user-config-list.module';
import { FaqModule } from './support-admin/faq/faq.module';
import { DemoMaterialModule } from './demomodule';
import { VipModule } from './support-admin/vip/vip.module';
import { CoreModule } from './core.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { KeycloakService } from './core/auth/keycloak.service';
import { AuthGuardService } from './core/guard/auth-guard.service';
import { SecuredHttpInterceptor } from './core/interceptor/secured-http.interceptor';
import { EnsureAuthenticated } from './service/_guards';
import { AuthenticationInterceptor } from './authentication/authentication.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavLogoComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    ToggleFullScreenDirective,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    CoreModule,
    NgbButtonsModule,
    MatExpansionModule,
    HttpClientModule,
    DemoMaterialModule,
    SocialLoginModule,
    FaqModule,
    VipModule,
    UserWithdrawRequestTracksListModule,
    UserWalletTrackListModule,
    AdminUserConfigListModule,
    ],
  providers: [
    
    KeycloakService,
    AuthGuardService,
    NavigationItem
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
