
<h5> Terms and conditions</h5>
<hr>
<div class="mat-elevation-z8 mt-4">
    <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
      <!-- Name Column -->
      <ng-container matColumnDef="screenName">
        <th mat-header-cell *matHeaderCellDef >Screen Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

  
      <ng-container matColumnDef="Language">
        <th mat-header-cell *matHeaderCellDef >Language</th>
        <td mat-cell *matCellDef="let element">
          {{ element.language }}
        </td>
      </ng-container>
  
  
      <ng-container matColumnDef="questions">
        <th mat-header-cell *matHeaderCellDef >Terms n Conditions</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.termsAndConditions.length }}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="actionsColumn">
        <th mat-header-cell *matHeaderCellDef >Actions</th>
        <td mat-cell *matCellDef="let element">
          <i
            class="fa fa-edit"
            [routerLink]="['/v2/product/content/faq/edit-terms/', element.name]"
            (click)="edit(element)"
            style="cursor: pointer;"
            [queryParams]="{ language: element.language, name: element.name }"
          >
          </i>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  
    <mat-paginator
    [pageSizeOptions]="[10, 25, 50, 100,200]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
  <div class="fixed-button active">
    <a routerLink="/v2/product/content/faq" class="btn-md btn-theme has-ripple mr-2">
        <i class="fa " aria-hidden="true"></i>
        Back
      </a>
    <a routerLink="/v2/product/content/faq/add-terms" class="btn-md btn-theme has-ripple mr-2">
      <i class="fa fa-plus" aria-hidden="true"></i>
      Terms 
    </a>
  </div>
  