import { Component, OnInit } from '@angular/core';
import { FAQService } from "src/app/service/support-admin/faq.service";
declare var swal: any;

@Component({
  selector: 'app-view-screen',
  templateUrl: './view-screen.component.html',
  styleUrls: ['./view-screen.component.scss']
})
export class ViewScreenComponent implements OnInit {

  screenNames=[]
  constructor(private faqservice:FAQService) { }

  ngOnInit(): void {
   this.getScreens()
  }

  getScreens(){
    this.faqservice.getScreenNames().subscribe((res) => {
      console.log(res.data);
      let data = res.data.filter(res=>{
        return res.is_deleted !=true
      })
      this.screenNames = data;
    });
  }
  

  delete(id,name){

    let data={
      is_active:true,
      is_deleted:true,
      name:name
    };
    this.faqservice.deleteScreenName(data,id).subscribe(res=>{
      if (res["success"] == 1) {
        swal.fire({
          title: "Success",
          text: "Screen Deleted Successfully",
          icon: "success",
        });
        this.getScreens()
      } else {
        swal.fire({
          title: "Error",
          text: res["error"][0],
          icon: "error",
        });
      }
    })
  }

}
