<div class="row mt-4">
    <div class="col-md-6">
        <h5>View Screens</h5>

    </div>

</div>
<hr />
<div class="row mt-3">
    <div class="col-md-3" *ngFor="let item of screenNames">
        <div class="card">

            <p> {{item.name}} 
            <i class="fa fa-edit"  [routerLink]="['/v2/product/content/faq/edit-screen',item._id]"></i>
            <i class="fa fa-trash red" (click)="delete(item._id,item.name)"></i>

        </p>
        
        </div>
    </div>
</div>

<div class="fixed-button active">
    <a routerLink="/v2/product/content/faq" class="btn-md btn-theme has-ripple mr-2">
      Back
    </a>
    <a routerLink="/v2/product/content/faq/add-screen" class="btn-md btn-theme has-ripple mr-2">
        <i class="fa fa-plus" aria-hidden="true"></i>
        Screen
      </a>
  </div>
