

<div class="row" style="padding: 10px 0px">
  <div class="col-md-3">
    <label> Screen Name <em>*</em></label>
    <ng-select
      [items]="screennames"
      bindLabel="name"
      bindValue="name"
      placeholder="Select Screen"
      [(ngModel)]="screen_name"
    >
    </ng-select>
  </div>
  <div class="col-md-3">
    <label>Language <em>*</em></label>
    <ng-select
      [items]="languages"
      bindLabel="name"
      bindValue="id"
      placeholder="Select Language"
      [(ngModel)]="language"
    >
    </ng-select>
    
  </div>
  <div class="col-md-3" style="margin-top: 35px;">
    <a (click)="getResult()" class="btn-md btn-theme has-ripple mr-2">
      Submit
    </a>
  </div>
  </div>

  <div class="" *ngIf="showresult">
    <h5 style="margin:3% 0%"> FAQ </h5>
    <table mat-table [dataSource]="dataSource"   matSort style="width: 100%">
      <ng-container matColumnDef="screenName">
        <th mat-header-cell *matHeaderCellDef >Screen Name</th>
        <td mat-cell *matCellDef="let element">{{ screen_name }}</td>
      </ng-container>
  
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef >Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.is_active }}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="Language">
        <th mat-header-cell *matHeaderCellDef >Language</th>
        <td mat-cell *matCellDef="let element">
          {{ element.language }}
        </td>
      </ng-container>
  
  
      <ng-container matColumnDef="questions">
        <th mat-header-cell *matHeaderCellDef >Questions</th>
        <td mat-cell *matCellDef="let element">
          {{ element.question_answers.length }}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="actionsColumn">
        <th mat-header-cell *matHeaderCellDef >Actions</th>
        <td mat-cell *matCellDef="let element">
          <i
            class="fa fa-edit"
            [routerLink]="['/v2/product/content/faq/edit/', screen_name]"
            (click)="edit(element)"
            [queryParams]="{ language: element.language, name: screen_name }"
          >
          </i>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <br>
    <h5 style="margin:3% 0%"> Terms n Conditions </h5>
    <table mat-table [dataSource]="dataSource2"  matSort style="width: 100%">
      <ng-container matColumnDef="screenName">
        <th mat-header-cell *matHeaderCellDef >Screen Name</th>
        <td mat-cell *matCellDef="let element">{{ screen_name }}</td>
      </ng-container>
  
     
  
      <ng-container matColumnDef="Language">
        <th mat-header-cell *matHeaderCellDef >Language</th>
        <td mat-cell *matCellDef="let element">
          {{ element.language }}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="terms">
        <th mat-header-cell *matHeaderCellDef >Terms</th>
        <td mat-cell *matCellDef="let element">
          {{ element.termsAndConditions.length }}
        </td>
      </ng-container>
  
     
  
      <ng-container matColumnDef="actionsColumn">
        <th mat-header-cell *matHeaderCellDef >Actions</th>
        <td mat-cell *matCellDef="let element">
          <i
            class="fa fa-edit"
            [routerLink]="['/v2/product/content/faq/edit-terms/', screen_name]"
            (click)="edit2(element)"
            [queryParams]="{ language: element.language, name: screen_name }"
          >
          </i>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
    </table>
    <mat-paginator
    [pageSizeOptions]="[10, 25, 50, 100,200]"
      showFirstLastButtons
    ></mat-paginator>
  </div>

<div class="fixed-button active">
  <a routerLink="/v2/product/content/faq/view-faq" class="btn-md btn-theme has-ripple mr-2">
    FAQ
  </a>
  <a routerLink="/v2/product/content/faq/view-screen" class="btn-md btn-theme has-ripple mr-2">
    Screens
  </a>
  <a routerLink="/v2/product/content/faq/view-terms" class="btn-md btn-theme has-ripple">
    Terms n Conditions
  </a>
</div>
