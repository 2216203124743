import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { commonData } from "./common";
import { EnsureAuthenticated, LoginRedirect } from "./service/_guards";
import { ViewWithdrawalComponent } from "./support-admin/user-withdraw-request-tracks/view-withdrawal/view-withdrawal.component";
import { AdminComponent } from "./theme/layout/admin/admin.component";
import { AuthComponent } from "./theme/layout/auth/auth.component";
import { AuthGuardService as AuthGuard } from '../app/core/guard/auth-guard.service';

const routes: Routes = [
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: "v2/promo/campaign",
        loadChildren: () =>
          import("./campaign/campaign-list/campaign-list.module").then(
            (m) => m.CampaignListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/product/config/power-coin",
        loadChildren: () =>
          import("./powercoins/powercoins-list/powercoins-list.module").then(
            (m) => m.PowerCoinsModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "v2/product/config/loyalty",
        loadChildren: () =>
          import("./loyaltyConfig/loyalty-list/loyalty-list.module").then(
            (m) => m.LoyaltyModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/product/config/loyalty-Points",
        loadChildren: () =>
          import("./loyaltyPointsConfig/loyalty-list/loyalty-list.module").then(
            (m) => m.LoyaltyPointsModule
          ),
        canActivate: [AuthGuard],
      },
      

      {
        path: "v2/promo/signup-bonus",
        loadChildren: () =>
          import(
            "./signup_bonus/signup-bonus-list/signup-bonus-list.module"
          ).then((m) => m.SignupBonusListModule),
        canActivate: [AuthGuard],
      },
      {
        path: "vip-contacts",
        loadChildren: () =>
          import(
            "./vip-contacts/vip-contacts-list/vip-contacts-list.module"
          ).then((m) => m.VipContactsListModule),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/promo/referrer-signup-bonus",
        loadChildren: () =>
          import(
            "./referrer-signup-bonus/referrer-signup-bonus-list/referrer-signup-bonus-list.module"
          ).then((m) => m.ReferrerSignupBonusListModule),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/promo/bonus-booster",
        loadChildren: () =>
          import(
            "./bonus-booster/bonus-booster-list/bonus-booster-list.module"
          ).then((m) => m.BonusBoosterListModule),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/promo/purchase-bonus",
        loadChildren: () =>
          import(
            "./purchase-bonus/purchase-bonus-list/purchase-bonus-list.module"
          ).then((m) => m.PurchaseBonusListModule),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/promo/login-bonus",
        loadChildren: () =>
          import("./login-bonus/login-bonus-list/login-bonus-list.module").then(
            (m) => m.LoginBonusListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/promo/power-coin-burn-bonus",
        loadChildren: () =>
          import(
            "./reward-points-burn-bonus/reward-points-burn-bonus-list/reward-points-burn-bonus-list.module"
          ).then((m) => m.RewardPointsBurnBonusListModule),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/promo/referrer-purchase-bonus",
        loadChildren: () =>
          import(
            "./referrer-purchase-bonus/referrer-purchase-bonus-list/referrer-purchase-bonus-list.module"
          ).then((m) => m.ReferrerPurchaseBonusListModule),
        canActivate: [AuthGuard],
      },

      {
        path: "challenge",
        loadChildren: () =>
          import("./challenge/challenge-list/challenge-list.module").then(
            (m) => m.ChallengeListModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "v2/product/content/banner",
        loadChildren: () =>
          import("./banners/banners-list/banners-list.module").then(
            (m) => m.BannersListModule
          ),
        canActivate: [AuthGuard],
      },

      // Design Pages
      {
        path: "",
        redirectTo: "v2/promo/dashboard/default",
        pathMatch: "full",
      },
      {
        path: "v2/promo/dashboard",
        loadChildren: () =>
          import("./demo/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "basic",
        loadChildren: () =>
          import("./demo/ui-elements/ui-basic/ui-basic.module").then(
            (m) => m.UiBasicModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "forms",
        loadChildren: () =>
          import("./demo/pages/form-elements/form-elements.module").then(
            (m) => m.FormElementsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "tables",
        loadChildren: () =>
          import("./demo/pages/tables/tables.module").then(
            (m) => m.TablesModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "charts",
        loadChildren: () =>
          import("./demo/pages/core-chart/core-chart.module").then(
            (m) => m.CoreChartModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "sample-page",
        loadChildren: () =>
          import("./demo/extra/sample-page/sample-page.module").then(
            (m) => m.SamplePageModule
          ),
        canActivate: [AuthGuard],
      },

      //SUPPORT-ADMIN
      {
        path: "v2/ops/withdraw-processing",
        loadChildren: () =>
          import(
            "./support-admin/user-withdraw-request-tracks/user-withdraw-request-tracks-list.module"
          ).then((m) => m.UserWithdrawRequestTracksListModule),
        canActivate: [AuthGuard],
      },

      {
        path: "v2/product/content/faq",
        loadChildren: () =>
          import("./support-admin/faq/faq.module").then((m) => m.FaqModule),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/support/vip-host",
        loadChildren: () =>
          import("./support-admin/vip/vip.module").then((m) => m.VipModule),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/support/user/search",
        loadChildren: () =>
          import("./support-admin/game-users-list/game-users-list.module").then(
            (m) => m.GameUsersListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/support/user/kyc",
        loadChildren: () =>
          import(
            "./support-admin/game-users-details/game-users-details.module"
          ).then((m) => m.GameUsersDetailsModule),
        canActivate: [AuthGuard],
      },

      {
        path: "v2/support/kyc/pan",
        loadChildren: () =>
          import(
            "./support-admin/kyc-approval-list/kyc-approval-list.module"
          ).then((m) => m.KycApprovalListModule),
        canActivate: [AuthGuard],
      },

      {
        path: "v2/support/user/wallet",
        loadChildren: () =>
          import(
            "./support-admin/user-wallet-track/user-wallet-track-list.module"
          ).then((m) => m.UserWalletTrackListModule),
        canActivate: [AuthGuard],
      },

      {
        path: "v2/support/user/payment",
        loadChildren: () =>
          import(
            "./support-admin/user-payments-track/user-payments-track-list.module"
          ).then((m) => m.UserpaymentsTrackListModule),
        canActivate: [AuthGuard],
      },

      {
        path: "v2/support/report/issues",
        loadChildren: () =>
          import(
            "./report-issues/report-issues.module"
          ).then((m) => m.ReportIssuesModule),
        canActivate: [AuthGuard],
      },

      {
        path: "get-active-bonus",
        loadChildren: () =>
          import(
            "./support-admin/get-active-bonus/get-active-bonus.module"
          ).then((m) => m.GetActiveBonusModule),
        canActivate: [AuthGuard],
      },

      //GAME-VERSIONS-TRACKS
      {
        path: "v2/product/release/android-rmg",
        loadChildren: () =>
          import(
            "./support-admin/game-versions-tracks/game-versions-tracks-list.module"
          ).then((m) => m.GameVersionsTracksListModule),
        canActivate: [AuthGuard],
        data: { role: [commonData.Role.Admin] },
      },

      //BET CONFIG RUMMY
      {
        path: "v2/product/config/bet/rummy",
        loadChildren: () =>
          import(
            "./support-admin/bet-configuration/bet-config-rummy/bet-config-rummy-list.module"
          ).then((m) => m.BetConfigRummyListModule),
        canActivate: [AuthGuard],
      },
      //BET CONFIG CALLBREAK
      {
        path: "v2/product/config/bet/callbreak",
        loadChildren: () =>
          import(
            "./support-admin/bet-configuration/bet-config-callbreak/bet-config-callbreak-list.module"
          ).then((m) => m.BetConfigCallBreakListModule),
        canActivate: [AuthGuard],
      },

      {
        path: "v2/product/config/bet/hot-tables",
        loadChildren: () =>
          import("./hotTables/hottables-list/hottables-list.module").then(
            (m) => m.HotTablesModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "v2/product/config/bet/currency",
        loadChildren: () =>
          import("./walletManagement/Currency/currency.module").then(
            (m) => m.CurrencyModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/product/config/bet/currency-exchange",
        loadChildren: () =>
          import("./walletManagement/Exchange-currency/exchange-currency.module").then(
            (m) => m.ExchangeCurrencyModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/product/config/bet/user-wallet",
        loadChildren: () =>
          import("./walletManagement/user-wallet/user-wallet.module").then(
            (m) => m.UserWalletModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/product/config/bet/user-wallet-config",
        loadChildren: () =>
          import("./walletManagement/user-wallet-config/user-wallet.module").then(
            (m) => m.UserWalletConfigModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/product/config/bet/organisation",
        loadChildren: () =>
          import("./wallets/Organisation/organisation.module").then(
            (m) => m.OrganisationListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/product/config/bet/application-wallet",
        loadChildren: () =>
          import("./wallets/application-wallet/application-wallet.module").then(
            (m) => m.ApplicationWalletModule
          ),
        canActivate: [AuthGuard],
      },
      
      {
        path: "v2/product/config/bet/apps",
        loadChildren: () =>
          import("./wallets/Apps/apps.module").then(
            (m) => m.AppsListModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/product/config/bet/organisation-wallet",
        loadChildren: () =>
          import("./walletManagement/Bank Templates/bank-template.module").then(
            (m) => m.OrganisationWalletModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "v2/product/config/bet/activity-transaction",
        loadChildren: () =>
          import("./walletManagement/Activity-Transaction/activity.module").then(
            (m) => m.ActivityModule
          ),
        canActivate: [AuthGuard],
      },



      //BET CONFIG POKER
      {
        path: "v2/product/config/bet/poker",
        loadChildren: () =>
          import(
            "./support-admin/bet-configuration/bet-config-poker/bet-config-poker-list.module"
          ).then((m) => m.BetConfigPokerListModule),
        canActivate: [AuthGuard],
      },
      {
        path: "powercoins",
        loadChildren: () =>
          import("./powercoins-config/powercoins-config.module").then(
            (m) => m.PowercoinsConfigModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/product/fraudusers",
        loadChildren: () =>
          import("./fraud-users/fraud-users.module").then(
            (m) => m.FraudUsersModule
          ),
        canActivate: [AuthGuard],
      },
      //Tournament

      {
        path: "v2/product/tournament/rummy",
        loadChildren: () =>
          import("./tournament/tournament.module").then(
            (m) => m.TournamentModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/ops/wallet/wallet-reversal",
        loadChildren: () =>
          import("./wallet-reversal/wallet-reversal.module").then(
            (m) => m.WalletReversalModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "v2/ops/manual-bonus",
        loadChildren: () =>
          import("./manual-bonus/manual-bonus.module").then(
            (m) => m.ManualBonusModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/ops/manual-bonus-chips",
        loadChildren: () =>
          import("./manual-bonus-chips/manual-bonus.module").then(
            (m) => m.ManualBonusChipsModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "v2/ops/wallet-withdraw",
        loadChildren: () =>
          import("./wallet-withdraw-requests/wallet-withdraw-requests.module").then(
            (m) => m.WalletWithdrawRequestsModule
          ),
        canActivate: [AuthGuard],
      },

      

      

      {
        path: "v2/ops/wallet/manage",
        loadChildren: () =>
          import("./add-cash/add-cash.module").then(
            (m) => m.AddCashModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "v2/ops/ban-states",
        loadChildren: () =>
          import("./ban-states/ban-states.module").then(
            (m) => m.BanStatesModule
          ),
        canActivate: [AuthGuard],
      },


      {
        path: "v2/ops/wallet/signifiers",
        loadChildren: () =>
          import("./wallet signifiers/wallet-signifier.module").then(
            (m) => m.WalletSignifierModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: "v2/devops/config/game-server",
        loadChildren: () =>
          import("./game-server/game-server.module").then(
            (m) => m.GameServerModule
          ),
        canActivate: [AuthGuard],
      },


      //ADMIN USER CONFIG
      {
        path: "admin-users",
        loadChildren: () =>
          import(
            "./support-admin/admin-user-config/admin-user-config-list.module"
          ).then((m) => m.AdminUserConfigListModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'view-withdraw',
    component: ViewWithdrawalComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
