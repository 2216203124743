import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FAQService } from 'src/app/service/support-admin/faq.service';
declare var swal:any
 
@Component({
  selector: 'app-view-terms',
  templateUrl: './view-terms.component.html',
  styleUrls: ['./view-terms.component.scss']
})
export class ViewTermsComponent implements OnInit {

  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    "screenName",
    "questions",
    'Language',
    "actionsColumn",
  ];

  constructor(private faqservice:FAQService) {}
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  ngOnInit() {
    this.getListofTerms()
  }
  getListofTerms(){
    this.faqservice.getTermsNames().subscribe(res=>{
      console.log(res)
      let data = res.data.filter(res=>{
        return res.termsAndConditions.length>0
      })
      this.dataSource.data=data
    }, error => {
      swal.fire({
        title: "Error",
        text: error,
        icon: "error",
      });
    })
  }

  edit(data){
    let saveobject = JSON.stringify(data)
    localStorage.setItem("terms",saveobject)
  }
}

