<div class="mat-elevation-z8 mt-4">
    <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
     
  
      <!-- Name Column -->
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef  > Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
  
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef >Mobile</th>
        <td mat-cell *matCellDef="let element">
          {{ element.phone_number }}
        </td>
      </ng-container>

      <ng-container matColumnDef="whatsapp">
        <th mat-header-cell *matHeaderCellDef >Whatsapp Number</th>
        <td mat-cell *matCellDef="let element">
          {{ element.whatsapp_number }}
        </td>
      </ng-container>
      
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef  >Email</th>
        <td mat-cell *matCellDef="let element">
          {{ element.email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="level">
        <th mat-header-cell *matHeaderCellDef  >Level</th>
        <td mat-cell *matCellDef="let element">
          <span *ngFor="let item of element.assigned_user_level,let last=last">{{item}}
            <span *ngIf="element.assigned_user_level.length > 1 && !last">,</span></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="languages">
        <th mat-header-cell *matHeaderCellDef  >Languages</th>
        <td mat-cell *matCellDef="let element">
          <span *ngFor="let item of element.languages,let last=last">{{item}}
            <span *ngIf="element.languages.length > 1 && !last">,</span></span>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="actionsColumn">
        <th mat-header-cell *matHeaderCellDef  >Actions 
        </th>
        <td mat-cell *matCellDef="let element">
         <i class="fa fa-edit mr-2" 
         [routerLink]="['/v2/support/vip-host/edit/',element._id]"> </i>
         <i class="fa fa-trash red" (click)="deleteVIP(element._id)"> </i>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  
    <mat-paginator
    [pageSizeOptions]="[10, 25, 50, 100,200]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
  <div class="fixed-button active">
    <a routerLink="/v2/support/vip-host/add" class="btn-md btn-theme has-ripple">
      <i class="fa fa-plus" aria-hidden="true"></i>
      Add
    </a>
  </div>