import { Component, OnInit, ViewChild } from "@angular/core";
import { errorMessage } from "src/app/messages";
import { UserWithdrawRequestTracksService } from "src/app/service/support-admin/user-withdraw-request-tracks.service";
declare var swal: any;

import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

// User interface
export class UserWithdrawRequestTracks {
  id: bigint;
  status: string;
  reason: string;
}

@Component({
  selector: "app-user-withdraw-request-tracks-list",
  styleUrls: ["./user-withdraw-request-tracks-list.component.scss"],
  templateUrl: "./user-withdraw-request-tracks-list.component.html",
})
export class UserWithdrawRequestTracksListComponent implements OnInit {
  loadingState = true;
  loadingState2 = true;
  objectArray: Array<any> = [];
  fraudreportArray: Array<any> = [];
  pagination: any = null;
  searchText: any = null;
  currentOffset: any = 0;

  startDate = null;
  endDate = null;
  searchID = null;

  cancelForm: FormGroup;
  closeResult: string;

  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false })
  set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  //displayedColumns: string[] = ['#','id','unique_id', 'beneId', 'transferId', 'userId', 'withdrawAmount', 'status', 'cd','actionsColumn'];
  displayedColumns: string[] = [
    "id",
    "unique_id",
    "beneId",
    "transferId",
    "fraudreport",
    "withdrawAmount",
    "status",
    "actionsColumn",
  ];

  constructor(
    private UserWithdrawRequestTracksService: UserWithdrawRequestTracksService,
    public fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.cancelForm = this.fb.group({
      id: [],
      status: [],
      reason: [],
    });
  }

  ngOnInit() {
    this.loadingState = true;
    this.getObjects();
  }

  showLoader: boolean = false;

  getObjects() {
    this.currentOffset = this.currentOffset != null ? this.currentOffset : 0;
    const params: any = { offset: this.currentOffset };
    if (this.searchText) {
      params.search = this.searchText;
    }

    if (this.searchID) {
      params.id = this.searchID;
    }

    if (this.searchStatus) {
      params.status = this.searchStatus;
    }
    if (this.startDate) {
      params.request_date = this.startDate;
    }
    this.UserWithdrawRequestTracksService.getUserWithdrawRequestTracksList(
      params
    ).subscribe(
      (response) => {
        this.loadingState = false;
          //      this.objectArray = response.data.data;
          //    this.dataSource.data  = response.data.data;
          this.objectArray = response;
          this.dataSource.data = response.result;
          this.dataSource.paginator = this.paginator;
          swal.fire({
            title: "Error",
            text: response.error[0],
            icon: "error",
          });
          this.showLoader = false;
          this.objectArray = [];
          this.pagination = null;
        },
      error => {
        swal.fire({
          title: "Error",
          text: error,
          icon: "error",
        });
      }
    );
  }

  getPage(offset: any) {
    if (offset != 4950) {
      let API_URL = environment.APIReportsEndPoint;
      offset = `${API_URL}${offset}`;
      console.log(offset);
      const urlParams = new URLSearchParams(offset);
      offset = urlParams?.get("offset");
    }

    console.log(this.currentOffset);
    this.currentOffset = offset != null ? offset : 0;
    this.getObjects();
  }

  searchbyID(val) {
    this.startDate = null;
    this.searchID = val;
    this.getObjects();
  }

  searchStatus: any = "Pending";

  searchbyStatus(val) {
    this.startDate = null;
    this.searchStatus = val;
    this.getObjects();
  }

  searchbyDate(val) {
    this.searchID = null;
    this.startDate = val;
    this.getObjects();
  }

  searchObject(text) {
    this.searchText = text;
    //this.currentOffset = 0;
    this.getObjects();
  }

  view(id) {
    this.loadingState2 = true;
    this.fraudreportArray = [];
    const params: any = { id: id };
    this.UserWithdrawRequestTracksService.getFraudReport(id).subscribe(
      (response) => {
        this.loadingState2 = false;
        if (response) {
          console.log(response);
          // this.fraudreportArray = response;
          let data = JSON.stringify(response);
          localStorage.setItem("viewData", data);
        } else {
          this.fraudreportArray = [];
        }
      },
      (error) => {
        this.fraudreportArray = [];
      }
    );
  }
  clearFilters() {
    this.startDate = null;
    this.endDate = null;
    this.searchText = "";
    this.searchID = "";
    this.searchStatus = "Pending";
    // this.dataSource.data = this.objectArray;
    this.getObjects();
    //console.log(this.dataSource.data);
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };

  applyFilter(filterValue: string, column) {
    //console.log(column);
    if (filterValue == "") {
      this.dataSource.data = this.objectArray;
    } else {
      this.dataSource.filterPredicate = (
        data: UserWithdrawRequestTracks,
        filtersJson: string
      ) => {
        const matchFilter = [];
        const filters = JSON.parse(filtersJson);

        filters.forEach((filter) => {
          const val = data[filter.id] === null ? "" : data[filter.id];
          matchFilter.push(
            val.toString().toLowerCase().includes(filter.value.toLowerCase())
          );
        });
        return matchFilter.every(Boolean);
      };

      if (column == "" || column == undefined) {
        column = "id";
      }

      const tableFilters = [];
      tableFilters.push({
        id: column,
        value: filterValue,
      });
      this.dataSource.filter = JSON.stringify(tableFilters);

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  changeStartDate(val) {
    this.dataSource.data = this.objectArray;
    let startDate = new Date(val);
    this.startDate = val;
    if (this.endDate == null) {
      this.endDate = new Date();
    }
    let endDate = new Date(new Date(this.endDate).setHours(23, 59, 59, 999));

    this.dataSource.data = this.dataSource.data.filter(
      (m) =>
        new Date(m["createdDate"]) >= startDate &&
        new Date(m["createdDate"]) <= endDate
    );
  }

  changeEndDate(val) {
    this.dataSource.data = this.objectArray;
    let startDate = new Date(this.startDate);
    let endDate = new Date(new Date(val).setHours(23, 59, 59, 999));
    this.endDate = val;

    this.dataSource.data = this.dataSource.data.filter(
      (m) =>
        new Date(m["createdDate"]) >= startDate &&
        new Date(m["createdDate"]) <= endDate
    );
  }

  // searchbyID(val){
  //   this.searchID = val;
  //   this.dataSource.data  = this.objectArray;
  //   if(val!=''){
  //     this.dataSource.data = this.dataSource.data.filter(m => m['id'] == val);
  //   }

  // }

  //CANCEL SUBMIT ACTION
  // cancelRequest(form) {
  //   if (form.valid) {
  //     // console.log(form.value);
  //     this.modalService.dismissAll();
  //     swal
  //       .fire({
  //         title: "Are you sure to Reject!",
  //         icon: "error",
  //         confirmButtonColor: "#4CAF50",
  //         confirmButtonText: "Yes!",
  //         showCancelButton: true,
  //         cancelButtonColor: "#d22346",
  //       })
  //       .then((willAccept) => {
  //         if (willAccept.isConfirmed) {
  //           this.UserWithdrawRequestTracksService.cancelUserWithdrawRequestTracks(
  //             form.value.id,
  //             form.value
  //           ).subscribe((response) => {
  //             if (response != "Forbidden") {
  //               swal
  //                 .fire({
  //                   title: "Success",
  //                   text: response.Reject_message,
  //                   icon: "success",
  //                 })
  //                 .then((result) => {
  //                   this.ngOnInit();
  //                 });
  //             } else {
  //               swal
  //                 .fire({
  //                   title: "Error",
  //                   text: "Failed to Reject Request",
  //                   icon: "error",
  //                 })
  //                 .then((result) => {
  //                   //this.ngOnInit();
  //                 });
  //             }
  //           });
  //         }
  //       });
  //   }
  // }

  //CANCEL OPEN POPUP ACTION
  


  fraudid: any
  fraud_type: string
  fraud_category: string
  fraud(id) {
    this.fraudid = id
  }
  clickfraud() {
    this.UserWithdrawRequestTracksService.FraudUserReport
      (this.fraudid, this.fraud_type, this.fraud_category, null).subscribe(res => {
        console.log(res)
        swal.fire({
          title: "Success",
          text: res.message,
          icon: "success",
        });
      })
  }

  //ACCEPT SUBMIT ACTION
  clickAccept(id) {
    swal
      .fire({
        title: "Are you sure to Accept!",
        icon: "error",
        confirmButtonColor: "#4CAF50",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        cancelButtonColor: "#d22346",
      })
      .then((willAccept) => {
        if (willAccept.isConfirmed) {
          this.UserWithdrawRequestTracksService.acceptUserWithdrawRequestTracks(
            id
          ).subscribe((response) => {
            this.modalService.dismissAll();
            if (response.success == 1) {
              swal
                .fire({
                  title: "Success",
                  text: "Accepted successfully",
                  icon: "success",
                })
                .then((result) => {
                  this.ngOnInit();
                });
            } else if(response.success == 0){
              swal
                .fire({
                  title: "Error",
                  text: response.error[0],
                  icon: "error",
                })
                .then((result) => {
                  //this.ngOnInit();
                });
            }
          });
        }
      });
  }

  clickCancel(url) {
    swal
    .fire({
      title: "Are you sure to Reject!",
      icon: "error",
      confirmButtonColor: "#4CAF50",
      confirmButtonText: "Yes!",
      showCancelButton: true,
      cancelButtonColor: "#d22346",
    })
    .then((willAccept) => {
      if (willAccept.isConfirmed) {
        this.UserWithdrawRequestTracksService.cancelUserWithdrawRequestTracks(
          url
        ).subscribe((response) => {
          this.modalService.dismissAll();
          if (response.success == 1) {
            swal
              .fire({
                title: "Success",
                text: "Rejected successfully",
                icon: "success",
              })
              .then((result) => {
                this.ngOnInit();
              });
          } else if(response.success == 0){
            swal
              .fire({
                title: "Error",
                text: response.error[0],
                icon: "error",
              })
              .then((result) => {
                //this.ngOnInit();
              });
          }
        });
      }
    });
  }
  
  //CANCEL SUBMIT ACTION
  cancelRequestURL(id) {
    // console.log(id);
    swal
      .fire({
        title: "Are you sure to Cancel!",
        icon: "error",
        confirmButtonColor: "#4CAF50",
        confirmButtonText: "Yes!",
        showCancelButton: true,
        cancelButtonColor: "#d22346",
      })
      .then((willAccept) => {
        if (willAccept.isConfirmed) {
          this.UserWithdrawRequestTracksService.cancelUserWithdrawRequestTracksURL(
            id
          ).subscribe((response) => {
            this.modalService.dismissAll();
            if (response != "Forbidden") {
              swal
                .fire({
                  title: "Success",
                  text: JSON.parse(response).msg,
                  icon: "success",
                })
                .then((result) => {
                  this.ngOnInit();
                });
            } else {
              swal
                .fire({
                  title: "Error",
                  text: "Failed to Cancel Request",
                  icon: "error",
                })
                .then((result) => {
                  //this.ngOnInit();
                });
            }
          });
        }
      });
  }





  addPost() {
    //($('#post-modal') as any).modal('show');
  }
  closePost() {
    this.modalService.dismissAll();
    //	($('#post-modal') as any).modal('hide');
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = "Closed with: ${result}";
        },
        (reason) => {
          this.closeResult = "Dismissed ${this.getDismissReason(reason)}";
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return "with: ${reason}";
    }
  }

  // getFraudReport(id, modal) {
  //   this.loadingState2 = true;
  //   this.fraudreportArray = [];
  //   this.open(modal)

  //   const params: any = { id: id };
  //   this.UserWithdrawRequestTracksService.getFraudReport(id).subscribe((response) => {
  //     this.loadingState2 = false;
  //     if (response) {
  //       this.fraudreportArray = response;
  //     } else {
  //       this.fraudreportArray = [];
  //     }
  //   }, (error) => {
  //     this.fraudreportArray = [];
  //   });
  // }
}
