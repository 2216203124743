import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserWithdrawRequestTracksService {

  //API_URL: string = environment.APIExpressEndpoint;
  API_URL: string = environment.APIReportsEndPoint;

  APi_URL_ADMIN : string =  environment.APIExpressEndpoint

  constructor(
    private http: HttpClient,
  ) { }

  getUserWithdrawRequestTracksList(param): Observable<any> {
    const headers= new HttpHeaders({
      'Access-Control-Allow-Headers': 'origin-list',
    });
    //return this.http.get(`${this.API_URL}/user-withdraw-request-tracks/`, { params: param });
    // Adding headers to fix CORS issue
    return this.http.get(`${this.API_URL}/ops/withdraw_requests/`,
     {params: param, 'headers': headers});
  }

  //https://stagereports.rummy777.com/fraud/user_fraud_report/296029

  getFraudReport(id): Observable<any> {
    //return this.http.get(`${this.API_URL}/user-withdraw-request-tracks/`, { params: param });
    return this.http.get(`${this.API_URL}/fraud/user_fraud_report/${id}`);
  }

  addUserWithdrawRequestTracks(data): Observable<any> {
    return this.http.post(`${this.API_URL}/user-withdraw-request-tracks/`, data);
  }

  FraudUserReport(user_id,fraud_type,fraud_category,data): Observable<any> {
    return this.http.post(`${this.API_URL}/ops/set_user_fraud/user_id/`
    +user_id+`/fraud_type/`+fraud_type+`/fraud_category/`+fraud_category,data)
  }

  cancelUserWithdrawRequestTracksURL(id): Observable<any> {
    return this.http.get(`${this.API_URL}/${id}`);
  }

  acceptUserWithdrawRequestTracks(id): Observable<any> {
    return this.http.get(`${this.APi_URL_ADMIN}${id}`);
  }

  cancelUserWithdrawRequestTracks(id): Observable<any> {
    return this.http.get(`${this.APi_URL_ADMIN}${id}`);
  }

  getUserWithdrawRequestTracksById(id, param = null): Observable<any> {
    return this.http.get(`${this.API_URL}/user-withdraw-request-tracks/${id}/`, { params: param });
  }

  deleteUserWithdrawRequestTracks(id): Observable<any> {
    return this.http.delete(`${this.API_URL}/user-withdraw-request-tracks/${id}/`);
  }
}
