import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { AdminUserConfigListComponent } from './admin-user-config-list.component';
import { AdminUserConfigService } from 'src/app/service/support-admin/admin-user-config.service';

import { MaterialModule } from '../../material.module';

const routes: Routes = [
  {
    path: '',
    component: AdminUserConfigListComponent,
  },
];

@NgModule({
  declarations: [AdminUserConfigListComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    MaterialModule
  ], providers: [AdminUserConfigService]
})

export class AdminUserConfigListModule { }
