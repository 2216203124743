<h5>Add Terms and Conditions</h5>
<hr />
<div class="row" style="padding: 10px 0px">
  <div class="col-md-3">
    <label> Screen Name <em>*</em></label>
    <ng-select
      [items]="screenNames"
      bindLabel="name"
      bindValue="name"
      placeholder="Select Screen"
      [(ngModel)]="data.screen_name"
    >
    </ng-select>
  </div>
  <div class="col-md-3">
    <label>Language <em>*</em></label>
    <ng-select
      [items]="languages"
      bindLabel="name"
      bindValue="id"
      placeholder="Select Language"
      [(ngModel)]="data.language"
    >
    </ng-select>
  </div>
</div>

<div cdkDropList class="example-list mt-3" (cdkDropListDropped)="drop($event)">
  <mat-accordion
    class="example-box"
    *ngFor="let item of data.termsAndConditions; let i = index"
    cdkDrag
  >
    <mat-expansion-panel
      class="mt-4"
      style="width: 99%"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="row" style="width: 100%">
            <div class="col-md-6">Term {{ i + 1 }}</div>
            <div class="col-md-6 text-right">
              <i
                class="fa fa-expand-arrows-alt"
                data-toggle="tooltip"
                title="Move"
              ></i>
              <i
                class="fa fa-trash"
                (click)="delete(i)"
                data-toggle="tooltip"
                title="Delete"
              ></i>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <label for="question"> Terms n Conditions </label>
      <div>
        <editor
        [(ngModel)]="item.content" name=''
        apiKey="nlf1hlezml1audh63gduute5hylhb4b4cwelop8kq57s3484"
        [init]="{
          height: 300,
          menubar: true,
          inline_styles : true,
          plugins: [
            'advlist autolink lists link image textcolor colorpicker charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | forecolor bold italic backcolor textcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help'
        }"
      ></editor>
      </div>
      <label class="mt-3" for="url"> URL </label>
      <div>
        <textarea rows="4" cols="50" style="padding: 5px;"
         
          name="url"
          placeholder="Enter URL"
          [(ngModel)]="item.url"
        ></textarea>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="row mt-4">
  <div class="col-md-6"></div>
  <div class="col-md-6 text-right">
    <button class="btn btn-primary" (click)="addQuestion()">+ Add</button>
    <button class="btn btn-success" (click)="submit()">Submit</button>
    <button class="btn btn-danger" routerLink="/v2/product/content/faq/view-terms">Cancel</button>
  </div>
</div>
