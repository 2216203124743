// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    APIExpressEndpoint: 'https://stageadminapi.rummy777.com',
    APIWalletpoint:'https://stagewallet.rummy777.com/api',
    APIEndpoint: 'https://stagev2ecs.rummy777.com/api',
    APIReportsEndPoint : 'https://stagereports.rummy777.com',
    APIBootConfigPoint : ' https://stageapi.rummy777.com',
    s3URL:"https://777games-public-assets-stage.s3.ap-south-1.amazonaws.com/",


    //keycloak urls
    baseUrl:'https://stageadmin.rummy777.com',
    // baseUrl: 'http://localhost:4200',
    realm: "stage-777-admin",
    url: "https://stageauth.rummy777.com/auth/",
    clientId: "stage-admin-ui",
    "public-client": true,
    "verify-token-audience": true,
    "use-resource-role-mappings": true,
    "confidential-port": 0,
    onLoad: "login-required",
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
