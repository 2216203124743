<h5>Add VIP</h5>
<hr />
<div  style="padding: 30px;
background: white;
height: calc(100vh - 100px);">

    <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" id="name"
                           required
                           [(ngModel)]="model.name" name="name"
                           #name="ngModel">
                    <div *ngIf="heroForm.submitted && !name.valid"
                         class="alert alert-danger">
                      Name is required
                    </div>
                  </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="email" class="form-control" id="email"
                           required
                           [(ngModel)]="model.email" name="email"
                           #email="ngModel">
                    <div *ngIf="heroForm.submitted && !email.valid"
                         class="alert alert-danger">
                      Email is required
                    </div>
                  </div>
            </div>
            
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="mobile">Mobile</label>
                    <input type="text" class="form-control" id="mobile"
                           required
                           [(ngModel)]="model.phone_number" name="mobile"
                           #mobile="ngModel">
                    <div *ngIf="heroForm.submitted && !mobile.valid"
                         class="alert alert-danger">
                      Mobile is required
                    </div>
                  </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="whatsapp">Whatsapp</label>
                    <input type="text" class="form-control" id="whatsapp"
                           required
                           [(ngModel)]="model.whatsapp_number" name="whatsapp"
                           #whatsapp="ngModel">
                    <div *ngIf="heroForm.submitted && !whatsapp.valid"
                         class="alert alert-danger">
                      Whatsapp Number is required
                    </div>
                  </div>
            </div>
           
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="name">Languages</label>
                    <mat-form-field appearance="fill">
                        <mat-label >Select </mat-label>

                        <mat-select [(value)]="model.languages"
                        required
                        multiple>
                            <mat-option value="english"> English </mat-option>
                            <mat-option value="hindi"> Hindi </mat-option>
                            <mat-option value="telugu"> Telugu </mat-option>
                        </mat-select>
                      </mat-form-field>
                
                  </div>
               
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="levels">Levels</label>

                    <mat-form-field appearance="fill">

                    <mat-label >Select </mat-label>

                        <mat-select [(value)]="model.assigned_user_level"
                        required
                        multiple>
                        <mat-option value="5"> VIP Classic </mat-option>
                        <mat-option value="6"> VIP Premium </mat-option>
                        <mat-option value="7"> VIP Royal </mat-option>
                        </mat-select>
                      </mat-form-field>
                  </div>
              
            </div>
        </div>
        <div class="text-right mt-4">
        <button type="submit" class="btn btn-success">Submit</button>
        <button type="button" class="btn btn-danger" routerLink="/v2/support/vip-host">Cancel</button>

</div>
        </form>
   
    </div>
