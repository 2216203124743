export class faqData {
  language: string;
  screen_name: string;
  question_answers: Array<any> = [
    {
      question: "",
      answer: "",
      url:"",
      show_on_main_page:false,
      priority: 0,
    },
  ];
}
