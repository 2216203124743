
<div class="accordion">

<mat-accordion>
  <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Fraud Details
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>Fraud Category : <span>{{fraudreportArray['report 3'].fraud_category}}</span></p>
    <p>Fraud Type : <span>{{fraudreportArray['report 3'].fraud_type}}</span></p>
    <p>Fraud Tags : <span>{{fraudreportArray['report 3'].fraud_tags | json}}</span></p>
  </mat-expansion-panel>
  
  </mat-accordion>
  <mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          User Details
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>User ID : <span>{{fraudreportArray['report 1'].user_id}}</span></p>
      <p>Name : <span>{{fraudreportArray['report 2'].unique_name}}</span></p>
      <p>Bank Account Name : <span>{{fraudreportArray['report 2'].bank_account_name}}</span></p>
      <p>Bank IFSC : <span>{{fraudreportArray['report 2'].bank_ifsc}}</span></p>
      <p>State : <span>{{fraudreportArray['report 2'].user_loc_bank_state}}</span></p>
      <p>DOB : <span>{{fraudreportArray['report 2'].dob}}</span></p>
      <p>Current Wallet : <span>{{fraudreportArray['report 2'].current_wallet}}</span></p>
    </mat-expansion-panel>
    
    </mat-accordion>
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            User Amount Details
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Latest Withdraw Request ID : <span>{{fraudreportArray['report 1'].latest_withdraw_request_id}}</span></p>
        <p>Latest Request Date : <span>{{fraudreportArray['report 1'].latest_request_date | date:'medium'}}</span></p>
        <p>Pending Amount : <span>{{fraudreportArray['report 1'].pending_amount}}</span></p>
        <p>Processed Amount : <span>{{fraudreportArray['report 1'].processed_amount}}</span></p>
  
        <p>Deposited Amount : <span>{{fraudreportArray['report 4'].deposited_amount}}</span></p>
  
        <p>Total Bonus : <span>{{fraudreportArray['report 5'].total_bonus}}</span></p>
        <p>Admin Remove : <span>{{fraudreportArray['report 5'].admin_remove}}</span></p>
        <p>Admin Add : <span>{{fraudreportArray['report 5'].admin_add}}</span></p>
        <p>Reward Points : <span>{{fraudreportArray['report 5'].reward_points}}</span></p>
        <p>Released Bonus : <span>{{fraudreportArray['report 5'].released_bonus}}</span></p>
        <p>Signup Bonus : <span>{{fraudreportArray['report 5'].signup_bonus}}</span></p>
        <p>Deposit OTC : <span>{{fraudreportArray['report 5'].deposit_otc}}</span></p>
        <p>Friend Deposit OTC : <span>{{fraudreportArray['report 5'].friend_deposit_otc}}</span></p>
        <p>Friend Signup OTC : <span>{{fraudreportArray['report 5'].friend_signup_otc}}</span></p>
      </mat-expansion-panel>
      
      </mat-accordion>

</div>
