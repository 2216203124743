import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  getToken(): string {
    return window.localStorage['_auth_bonus777_'];
  }

  saveToken(token: string, user_details:any) {
    window.localStorage['_auth_bonus777_'] = token;
    window.localStorage['user_email'] = user_details.email;
    window.localStorage['user_status'] = user_details.status;
    // Promotions:any;
    // Product: any;
    // Support: any;
    // Finance: any;
    // Admin: any;
    window.localStorage['user_role_Promotions'] = user_details?.role?.Promotions;
    window.localStorage['user_role_Product'] = user_details?.role?.Product;
    window.localStorage['user_role_Support'] = user_details?.role?.Support;
    window.localStorage['user_role_Finance'] = user_details?.role?.Finance;
    window.localStorage['user_role_Admin'] = user_details?.role?.Admin;
  }

  destroyToken() {
    window.localStorage.removeItem('_auth_bonus777_');
    window.localStorage.removeItem('user_email');
    window.localStorage.removeItem('user_status');
    window.localStorage.removeItem('user_role_Promotions');
    window.localStorage.removeItem('user_role_Product');
    window.localStorage.removeItem('user_role_Support');
    window.localStorage.removeItem('user_role_Finance');
    window.localStorage.removeItem('user_role_Admin');
    window.localStorage.clear();
    
  }
}
