import { Component, OnInit } from "@angular/core";
import { Question } from "../../models/questionData"
import { faqData } from "../../models/faqdata";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FAQService } from "src/app/service/support-admin/faq.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
declare var swal: any;
declare var tinymce: any;

@Component({
  selector: "app-add-faq",
  templateUrl: "./add-faq.component.html",
  styleUrls: ["./add-faq.component.scss"],
})
export class AddFaqComponent implements OnInit {
  panelOpenState: boolean;

  data = new faqData();
  htmlContent = '';
  lang: string = "";
  name: string = "";
  
  languages = [
    { id: "en", name: "English" },
    { id: "ta", name: "TA" },
    { id: "fj", name: "FJ" },
  ];

  constructor(
    private faqservice: FAQService,
    private activate: ActivatedRoute,
    private router: Router
  ) {}

  screenNames = [];
  ngOnInit(): void {
    this.getScreens();
    this.lang = this.activate.snapshot.queryParams.language;
    this.name = this.activate.snapshot.queryParams.name;
    if (this.lang) {
      this.getScreenById();
    }
    
  }

  getScreens() {
    this.faqservice.getScreenNames().subscribe((res) => {
      console.log(res.data);
      let data = res.data.filter(res=>{
        return res.is_deleted !=true
      })
      this.screenNames = data;
    });
  }
  addQuestion() {
    this.data.question_answers.push(new Question());
  }

  delete(id) {
    this.data.question_answers.splice(id, 1);
  }

  submit() {
    this.data.question_answers.map((res, index) => {
      res.priority = index + 1;
    });
    if (this.lang) {
      this.faqservice
        .getScreenById(this.lang, this.data, this.name)
        .subscribe((res) => {
          console.log(res["success"]);
          if (res["success"] == 1) {
            swal.fire({
              title: "Success",
              text: "Screen Updated Successfully",
              icon: "success",
            });
            this.router.navigateByUrl("/v2/product/content/faq/view-faq");
          } else {
            swal.fire({
              title: "Error",
              text: res["error"][0],
              icon: "error",
            });
          }
        }, error => {
          swal.fire({
            title: "Error",
            text: error,
            icon: "error",
          });
        });
    } else {
      this.faqservice.addScreen(this.data).subscribe((res) => {
        console.log(res["success"]);
        if (res["success"] == 1) {
          swal.fire({
            title: "Success",
            text: "Screen Added Successfully",
            icon: "success",
          });
          this.router.navigateByUrl("/v2/product/content/faq/view-faq");
        } else {
          swal.fire({
            title: "Error",
            text: res["error"][0],
            icon: "error",
          });
        }
      }, error => {
        swal.fire({
          title: "Error",
          text: error,
          icon: "error",
        });
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.data.question_answers,
      event.previousIndex,
      event.currentIndex
    );
  }

  getScreenById() {
    let data = JSON.parse(localStorage.getItem("questions"));
    this.faqservice
      .getScreenById(this.lang, data, this.name)
      .subscribe((res) => {
        console.log(res);
        if (res) {
          let data = {
            language: res.data.language,
            screen_name: res.data.screen_id,
            question_answers: res.data.question_answers,
          };
          this.data = data;
          this.data.screen_name = this.name;
        }
      }, error => {
        swal.fire({
          title: "Error",
          text: error,
          icon: "error",
        });
      });
  }
}
