import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { UserWithdrawRequestTracksListComponent } from './user-withdraw-request-tracks-list.component';
import { UserWithdrawRequestTracksService } from 'src/app/service/support-admin/user-withdraw-request-tracks.service';
import {MatExpansionModule} from '@angular/material/expansion';
import { MaterialModule } from '../../material.module';
import { ViewWithdrawalComponent } from './view-withdrawal/view-withdrawal.component';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  {
    path: '',
    component: UserWithdrawRequestTracksListComponent,
  },
];

@NgModule({
  declarations: [UserWithdrawRequestTracksListComponent, ViewWithdrawalComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    MatExpansionModule,
    MaterialModule,
    FormsModule
  ], providers: [UserWithdrawRequestTracksService]
})

export class UserWithdrawRequestTracksListModule { }
