import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { FAQService } from "src/app/service/support-admin/faq.service";
declare var swal:any
@Component({
  selector: "app-faq",
  styleUrls: ["./faq.component.scss"],
  templateUrl: "./faq.component.html",
})
export class FaqComponent implements OnInit {
  dataSource = new MatTableDataSource();
  dataSource2 = new MatTableDataSource();
  screen_name:string
  language:string
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;
  languages = [
    { id: "en", name: "English" },
    { id: "ta", name: "TA" },
    { id: "fj", name: "FJ" },
  ];
  displayedColumns: string[] = [
    "screenName",
    "status",
    "questions",
    'Language',
    "actionsColumn",
  ];
  displayedColumns2: string[] = [
    "screenName",
    "terms",
    'Language',
    "actionsColumn",
  ];
  constructor(private faqservice:FAQService) {}
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  ngOnInit() {
    this.getListofScreens()
  }

  screennames=[]
  getListofScreens(){
    this.faqservice.getScreensList().subscribe(res=>{
      console.log(res)
      this.screennames=res.data
    }, error => {
      swal.fire({
        title: "Error",
        text: error,
        icon: "error",
      });
    })
  }

  edit(data){
    let saveobject = JSON.stringify(data)
    localStorage.setItem("questions",saveobject)
  }
  edit2(data){
    let saveobject = JSON.stringify(data)
    localStorage.setItem("terms",saveobject)
  }

  showresult:boolean=false
  getResult(){
    this.faqservice.getTermsbyscreen(this.language,this.screen_name).subscribe(res=>{
      this.showresult=true
      this.dataSource2.data=res.data.termsAndConditions
    }, error => {
      swal.fire({
        title: "Error",
        text: error,
        icon: "error",
      });
    })
    this.faqservice.getFaqbyscreen(this.language,this.screen_name).subscribe(res=>{
      this.showresult=true
      this.dataSource.data=res.data.questionAnswers
    }, error => {
      swal.fire({
        title: "Error",
        text: error,
        icon: "error",
      });
    })
  }
  
  
 
}
