import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DataService } from './service/data.service';
import { AdminUserConfigService } from './service/support-admin/admin-user-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isAuthenticated: boolean;
  currentUser: any = false;
  currentUser_details: any = false;


  constructor(
    private router: Router,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private adminUserConfig : AdminUserConfigService

  ) {
    this.dataService.isAuthenticated.subscribe((isLoggedIn) => {
      this.isAuthenticated = isLoggedIn;
    })
  }

  ngOnInit() {
    // this.getDetails();
    if (this.isAuthenticated) {
      this.activatedRoute.snapshot.queryParamMap.get('next');
      const nextURL = this.activatedRoute.snapshot.queryParamMap.get('next') ?
        this.activatedRoute.snapshot.queryParamMap.get('next') : '/v2/promo/dashboard/default';
      this.router.navigateByUrl(nextURL);
    } else {
      // this.dataService.purgeAuth()
      // "else isAuthenticated if", this.isAuthenticated);
      // this.router.navigateByUrl('/auth/signin');
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  // getDetails() {
  //   this.authService.authState.subscribe((user) => {
  //     "App Component user", user);
  //     " ngOnInit 222 App Component user");
  //     if (user) {
  //       let email_check = user['email'];
  //     this.adminUserConfig.getAdminUserConfigById(email_check).subscribe(response=>{
  //       let user_details = response.data;
  //       if(user_details.status==1){
  //         this.currentUser_details = user_details;
  //         this.currentUser = user;
  //         this.dataService.setAuth(user, user_details)
  //       }else{
  //         this.dataService.purgeAuth()
  //         this.router.navigateByUrl('/auth/signin');  
  //       }
  //     });
  //     } else {
  //       " ngOnInit 222 else ");
  //       this.dataService.purgeAuth()
  //       this.router.navigateByUrl('/auth/signin');
  //     }
  //   }, (error) => {
  //     " ngOnInit 222 error ", error);
  //     this.dataService.purgeAuth()
  //     this.router.navigateByUrl('/auth/signin');
  //   });
  //   // "currentUser",this.currentUser);
  //   // if (!this.currentUser) {
  //   //   "2 currentUser",this.currentUser);
  //   //   this.dataService.purgeAuth()
  //   //   this.router.navigateByUrl('/auth/signin');
  //   // }

  // }
}
