import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class FAQService {
  API_URL: string = environment.APIExpressEndpoint;
  //   API_URL: string = environment.APIReportsEndPoint;

  constructor(private http: HttpClient) {}

  getScreensList(): Observable<any> {
    return this.http.get(
      `${this.API_URL}/v2/product/content/faq/screen_name_wise_question_answers_summary`
    );
  }

  getScreenById(id, data, name): Observable<any> {
    return this.http.patch(
      `${this.API_URL}/v2/product/content/faq/` + name + "?language=" + id,
      data
    );
  }
  getTermsById(id, data, name): Observable<any> {
    return this.http.patch(
      `${this.API_URL}/v2/product/content/terms_and_conditions/` + name + "?language=" + id,
      data
    );
  }
  addScreen(params) {
    return this.http.post(`${this.API_URL}/v2/product/content/faq/`, params);
  }

  addTerms(params) {
    return this.http.post(`${this.API_URL}/v2/product/content/terms_and_conditions/`, params);
  }
 
  getScreenNames(): Observable<any> {
    // return this.http.get(`${this.API_URL}/fraud/user_fraud_report/${id}`);
    return this.http.get(`${this.API_URL}/v2/product/content/screen_names/`);
  }

  getTermsNames(): Observable<any> {
    // return this.http.get(`${this.API_URL}/fraud/user_fraud_report/${id}`);
    return this.http.get(`${this.API_URL}/v2/product/content/terms_and_conditions/screen_name_wise_terms_and_condition_summary/`);
  }

  getTermsbyscreen(id, name): Observable<any> {
    return this.http.get(`${this.API_URL}/v2/product/content/terms_and_conditions/` + name + "?language=" + id);
  }

  getFaqbyscreen(id, name): Observable<any> {
    return this.http.get(`${this.API_URL}/v2/product/content/faq/` + name + "?language=" + id);
  }

  addScreeName(params){
    return this.http.post(`${this.API_URL}/v2/product/content/screen_names/`, params);
  }
  editScreenName(id){
    return this.http.get(`${this.API_URL}/v2/product/content/screen_names/`+id);
  }

  updateScreenName(params,id){
    return this.http.patch(`${this.API_URL}/v2/product/content/screen_names/`+id, params);
  }

  deleteScreenName(params,id){
    return this.http.delete(`${this.API_URL}/v2/product/content/screen_names/`+id,params);
  }
}
