import { Component, OnInit, ViewChild } from '@angular/core';
import { errorMessage } from 'src/app/messages';
import { UserWalletTrackService } from 'src/app/service/support-admin/user-wallet-track.service';
declare var swal: any;

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSort} from '@angular/material/sort';

import {FormGroup, FormBuilder } from '@angular/forms';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { idText } from 'typescript';

// User interface
export class UserWalletTrack {
  id:bigint;
  gt:string;
  reason:string;
}

@Component({
  selector: 'app-user-wallet-track-list',
  templateUrl: './user-wallet-track-list.component.html',
  styleUrls:['./user-wallet-track-list.component.css']

})




export class UserWalletTrackListComponent implements OnInit {
 
  loadingState = true;
  objectArray: Array<any> = [];
  pagination: any = null;
  searchText: any = null;
  currentPage: any = 1;

  startDate=null;
  endDate =null;
  searchID = '';

  cancelForm:FormGroup;
	closeResult: string;



  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort, {static: false})
set sort(sort: MatSort) {
   this.dataSource.sort = sort;
}

// "Opening_Bal" : 4.90999999999999,
// "Closing_Bal" : 54.91,
// "Trnx_Amount" : 50,
// "Trnx_Type_Txt" : "Add Cash From Admin OTC",
// "gt" : "",
// "bv" : 0,
// "cd" : ISODate("2020-09-30T09:44:12.678Z")

//displayedColumns: string[] = ['#','id','Opening_Bal', 'Closing_Bal', 'Trnx_Amount', 'userId', 'Trnx_Type_Txt', 'gt', 'cd','bv']; 
displayedColumns: string[] = ['#','id','Opening_Bal', 'Closing_Bal', 'Trnx_Amount', 'Trnx_Type_Txt', 'gt', 'bv', 'cd',]; 

  constructor(
    private UserWalletTrackService: UserWalletTrackService,
    public fb:FormBuilder,
    private modalService: NgbModal,

  ) {
    this.cancelForm = this.fb.group({
      id:[],
      gt: [],
      reason: [],
      });
   }

  
 

  ngOnInit() {
    this.loadingState = false;
  }

  
  getObjects(id) {
    this.loadingState = true;
    const params: any = {  };
    if (this.searchText) {
      params.search = this.searchText;
    }
    this.UserWalletTrackService.getUserWalletTrackById(id,params).subscribe(
    response => {
      this.loadingState = false;
      if (response.success == 1) {
        this.objectArray = response.data;
        this.dataSource.data  = response.data;
				this.dataSource.paginator = this.paginator;
      } else if(response.success == 0){
        swal.fire({
          title: "Error",
          text: response.error[0],
          icon: "error",
        });
        this.objectArray = [];
        this.pagination = null;
      }
    }, 
    error => {
      swal.fire({
        title: "Error",
        text: error,
        icon: "error",
      });
    })
  }


  

  searchObject(text) {
    this.searchText = text;
    this.currentPage = 1;
   // this.getObjects(idText);
  }

  
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

    applyFilter(filterValue: string, column) {
      //console.log(column);
      if(filterValue ==''){
        this.dataSource.data = this.objectArray;
      }else{
         this.dataSource.filterPredicate = 
            (data: UserWalletTrack, filtersJson: string) => {
              const matchFilter = [];
              const filters = JSON.parse(filtersJson);
            
              filters.forEach(filter => {
                const val = data[filter.id] === null ? '' : data[filter.id];
                  matchFilter.push(val.toString().toLowerCase().includes(filter.value.toLowerCase()));
              });
                return matchFilter.every(Boolean);
              };
              
              if(column == '' || column==undefined){
                column = 'id';
              }
    
    const tableFilters = [];
    tableFilters.push({
      id: column,
      value: filterValue
    },
    );
    this.dataSource.filter = JSON.stringify(tableFilters);
    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    
  }
    }
    
     changeStartDate(val){
      this.dataSource.data  = this.objectArray;
       let startDate = new Date(val);
       this.startDate = val;
       if(this.endDate == null){
         this.endDate = new Date();
       }
 let endDate = new Date(new Date(this.endDate).setHours(23, 59, 59, 999));

 this.dataSource.data = this.dataSource.data.filter(m => new Date(m['cd']) >= startDate && new Date(m['cd']) <= endDate);

    }

    changeEndDate(val){
      this.dataSource.data  = this.objectArray;
       let startDate = new Date(this.startDate);
 let endDate = new Date(new Date(val).setHours(23, 59, 59, 999));
 this.endDate = val;

 this.dataSource.data = this.dataSource.data.filter(m => new Date(m['cd']) >= startDate && new Date(m['cd']) <= endDate);
    }

    clearFilters(){
      this.startDate=null;
      this.endDate =null;
      this.dataSource.data = this.objectArray;
      console.log(this.dataSource.data);
    }


    searchbyID(val){
      this.searchID = val;
      //this.dataSource.data  = this.objectArray;
      if(val!=''){
        this.getObjects(val);

//        this.dataSource.data = this.dataSource.data.filter(m => m['id'] == val);
      }else{
        this.dataSource.data = [];

      }


    }

    searchbyStatus(val){

      if(val!='' && this.searchID==''){
        this.dataSource.data  = this.objectArray;
        this.dataSource.data = this.dataSource.data.filter(m => m['gt'] == val);
      }else if(this.searchID!='' && val!=''){
        this.dataSource.data  = this.objectArray;
        this.dataSource.data = this.dataSource.data.filter(m => m['gt'] == val && m['id']==this.searchID);
      }else if(this.searchID!='' && val==''){
          this.searchbyID(this.searchID)
      }else{
        this.dataSource.data  = this.objectArray;
      }
      

    }


   
    
		addPost(){
			//($('#post-modal') as any).modal('show');	
		}
		 closePost() {
      this.modalService.dismissAll();
		//	($('#post-modal') as any).modal('hide');	
      }
      
      open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = 'Closed with: ${result}';
        }, (reason) => {
          this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
        });
        }
        
        private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return  'with: ${reason}';
        }
        }
}




