import { Component, OnInit, ViewChild } from '@angular/core';
import { commonData, CommonFunction, urlRegex } from "src/app/common";
import { AdminUserConfigService } from 'src/app/service/support-admin/admin-user-config.service';
declare var swal: any;

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort} from '@angular/material/sort';

import {FormGroup, FormBuilder, Validators } from '@angular/forms';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

// ADMIN USER CONFIG 
export class AdminUserConfig {
    _id:any;
    email:bigint;
    displayName: string;
    role: string;
    created_date: number;
    updated_date: number;
    last_login: string;
    status: boolean;
}

// ADMIN ROLE 
export class roles {
  Promotions:any;
  Product: any;
  Support: any;
  Finance: any;
  Admin: any;
}

@Component({
  selector: 'app-admin-user-config-list',
  styleUrls:['./admin-user-config-list.component.css'],
  templateUrl: './admin-user-config-list.component.html',

})




export class AdminUserConfigListComponent implements OnInit {
 
  loadingState = true;
  objectArray: Array<any> = [];
  pagination: any = null;
  searchText: any = null;
  currentPage: any = 1;

  startDate=null;
  endDate =null;
  searchID = '';

  addForm:FormGroup;
  cancelForm:FormGroup;
	closeResult: string;

  rolesArray:any =[];
  selectedvariant:any;

  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort, {static: false})
set sort(sort: MatSort) {
   this.dataSource.sort = sort;
}


displayedColumns: string[] = ['#','displayName','email','role','last_login', 'status', 'created_date','actionsColumn']; 

  constructor(
    private AdminUserConfigService: AdminUserConfigService,
    public fb:FormBuilder,
    private modalService: NgbModal,

  ) {
    this.addForm = this.fb.group({
      _id :[null],
         email:[null],
         displayName:[null],
         role:[null],
         created_date:[null],
         updated_date: [null],  
         last_login: [null], 
         status: [null],
     });
   }

  
 

  ngOnInit() {
    this.loadingState = true;
   this.getObjects();
   this.getMasterData();

  }

  getMasterData() {
    this.rolesArray = commonData.ROLES;
  }

  getObjects() {
    const params: any = { page: this.currentPage };
    if (this.searchText) {
      params.search = this.searchText;
    }
    this.AdminUserConfigService.getAdminUserConfigList(params).subscribe((response) => {
      this.loadingState = false;
      if (response.success == 1) {
        this.objectArray = response.data.data;
        this.dataSource.data  = response.data.data;
				this.dataSource.paginator = this.paginator;

      } else if(response.success == 0) {
        this.objectArray = [];
        swal.fire({
          title: "Error",
          text: response.error[0],
          icon: "error",
        });
        this.pagination = null;
      }
    }, (error) => {
      this.loadingState = false;
      this.objectArray = [];
      this.pagination = null;
    });
  }


  getPage(page: number) {
    this.currentPage = page;
    this.getObjects();
  }

  searchObject(text) {
    this.searchText = text;
    this.currentPage = 1;
    this.getObjects();
  }

  
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

    applyFilter(filterValue: string, column) {
      //console.log(column);
      if(filterValue ==''|| filterValue==null || filterValue=='null'){
        this.dataSource.data = this.objectArray;
      }else{
         this.dataSource.filterPredicate = 
            (data: AdminUserConfig, filtersJson: string) => {
              const matchFilter = [];
              const filters = JSON.parse(filtersJson);
            
              filters.forEach(filter => {
                const val = data[filter.id] === null ? '' : data[filter.id];
                  matchFilter.push(val.toString().toLowerCase().includes(filter.value.toLowerCase()));
              });
                return matchFilter.every(Boolean);
              };
              
              if(column == '' || column==undefined){
                column = 'email';
              }
    
    const tableFilters = [];
    tableFilters.push({
      id: column,
      value: filterValue
    },
    );
    this.dataSource.filter = JSON.stringify(tableFilters);
    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    
  }
    }
    
     changeStartDate(val){
      this.dataSource.data  = this.objectArray;
       let startDate = new Date(val);
       this.startDate = val;
       if(this.endDate == null){
         this.endDate = new Date();
       }
 let endDate = new Date(new Date(this.endDate).setHours(23, 59, 59, 999));

 this.dataSource.data = this.dataSource.data.filter(m => new Date(m['createdDate']) >= startDate && new Date(m['createdDate']) <= endDate);

    }

    changeEndDate(val){
      this.dataSource.data  = this.objectArray;
       let startDate = new Date(this.startDate);
 let endDate = new Date(new Date(val).setHours(23, 59, 59, 999));
 this.endDate = val;

 this.dataSource.data = this.dataSource.data.filter(m => new Date(m['createdDate']) >= startDate && new Date(m['createdDate']) <= endDate);
    }

    clearFilters(){
      this.startDate=null;
      this.endDate =null;
      this.dataSource.data = this.objectArray;
      console.log(this.dataSource.data);
    }


    searchbyID(val){
      this.searchID = val;
      this.dataSource.data  = this.objectArray;
      if(val!=''){
        this.dataSource.data = this.dataSource.data.filter(m => m['id'] == val);
      }

    }

    searchbyStatus(val){

      if(val!='' && this.searchID==''){
        this.dataSource.data  = this.objectArray;
        this.dataSource.data = this.dataSource.data.filter(m => m['status'] == val);
      }else if(this.searchID!='' && val!=''){
        this.dataSource.data  = this.objectArray;
        this.dataSource.data = this.dataSource.data.filter(m => m['status'] == val && m['id']==this.searchID);
      }else if(this.searchID!='' && val==''){
          this.searchbyID(this.searchID)
      }else{
        this.dataSource.data  = this.objectArray;
      }
      

    }




    

            //ADD SUBMIT ACTION
            createOrUpdatePost(form) {
              if (form.valid) {
               // console.log(form.value);
              let newdata = form.value;
    const form_data = {};
    
    for ( var key in newdata ) {
      let value = newdata[key];
      let new_roles = <roles>{};
      if(key=='role'){
       if(value){
         //CHECK BY ROLES ARRAY
        this.rolesArray.forEach(element => {
      var target_role=value.find(temp=>temp==element.value); //FINDI OR NOT
      if(target_role){
        new_roles[element.value]=1;
      }
      else{
        new_roles[element.value]=0;
      }
       });

      }else{
        new_roles.Admin=0;
        new_roles.Finance=0;
      }
      form_data[key]=new_roles;
     }else if(value!='null' && value!=null && value!=''){
      form_data[key]=value;
     }else{
      //form_data[key]=value;
     }
    }

   console.log(form_data);
              if(form.value._id!=null){
                this.loadingState = true;
                this.AdminUserConfigService.editAdminUserConfig(form.value._id, form_data).subscribe((response) => {
                 // console.log(response);
                  this.loadingState = false;
    
                   this.modalService.dismissAll();
                   if(response.success==1){
                  
                    swal.fire({
                     title: "Success",
                        text: response.message,
                        icon: "success",
                    }).then((result) => {
                   this.ngOnInit();
                    });
                   }else{
                     swal.fire({
                       title: "Error",
                          text: 'Failed to Update',
                          icon: "error",
                      }).then((result) => {
                     //this.ngOnInit();
                      });
                   }
           
                 });
    
              }else{
                this.loadingState = true;
               this.AdminUserConfigService.addAdminUserConfig(form_data).subscribe((response) => {
                console.log(response);
                this.loadingState = false;
                this.modalService.dismissAll();
                 if(response.success==1){
                swal.fire({
                   title: "Success",
                      text: response.message,
                      icon: "success",
                  }).then((result) => { 
                 this.ngOnInit();
                  });
                 }else{
                   swal.fire({
                     title: "Error",
                        text: 'Failed to Add',
                        icon: "error",
                    }).then((result) => {
                   //this.ngOnInit();
                    });
                 }
    
               });
              }
             }
            }
    
		addPost(){
			//($('#post-modal') as any).modal('show');	
		}
		 closePost() {
      this.modalService.dismissAll();
		//	($('#post-modal') as any).modal('hide');	
      }

      createPost(model){
        this.open(model);
        this.addForm = this.fb.group({
          _id :[null],
             email:[null,  Validators.compose([Validators.required])],
             displayName:[null,  Validators.compose([Validators.required])],
             role:[null,  Validators.compose([Validators.required])],
             status: [1],
         });
        }

        editPost(adminuserconfig: AdminUserConfig, model){
          this.open(model);
         // console.log(adminuserconfig.role);
          var roles_obj = adminuserconfig.role;
          var roles_edit_array_all = Object.entries(roles_obj).map(([key, value]) => (roles_obj[key]==1?key:null));
          var roles_edit_array=[];
          roles_edit_array_all.forEach(element => {
            if(element){
            roles_edit_array.push(element)
            }
          });

          this.addForm = this.fb.group({
             _id :[adminuserconfig._id,  Validators.compose([Validators.required])],
             email:[adminuserconfig.email,  Validators.compose([Validators.required])],
             displayName:[adminuserconfig.displayName,  Validators.compose([Validators.required])],
             role:[roles_edit_array],
             created_date:[adminuserconfig.created_date],
             updated_date: [adminuserconfig.updated_date], 
             last_login: [adminuserconfig.last_login],
             status: adminuserconfig.status,
    
           });
          //console.log(adminuserconfig);

          }
      

      
      open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = 'Closed with: ${result}';
        }, (reason) => {
          this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
        });
        }
        
        private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return  'with: ${reason}';
        }
        }

        roles_text(role){
          let roles_text='';
          roles_text+= role?.Promotions==1?'Promotions':'';
          roles_text+= role?.Product==1?', Product':'';
          roles_text+= role?.Support==1?', Support':'';
          roles_text+= role?.Finance==1?', Finance':'';
          roles_text+= role?.Admin==1?', Admin':'';

          return roles_text;

        }
}




