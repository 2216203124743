import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { VipComponent } from './vip.component';
import { DemoMaterialModule } from 'src/app/demomodule';
import { FAQService } from 'src/app/service/support-admin/faq.service';
import { AddVipComponent } from './add-vip/add-vip.component';
import { FormsModule } from '@angular/forms';


const routes: Routes = [
  {
    path: '',
    component: VipComponent,
  },
  {
    path:'add',
    component:AddVipComponent
  },
  {
    path:'edit/:id',
    component:AddVipComponent
  }
];

@NgModule({
  declarations: [VipComponent, AddVipComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule,
    MatExpansionModule,
    DemoMaterialModule,
    MaterialModule
  ], providers: [FAQService]
})
export class VipModule { }
