import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VipContactsService } from 'src/app/service/vip-contacts.service';
import {VIP} from '../models/data'
declare var swal: any;

@Component({
  selector: 'app-add-vip',
  templateUrl: './add-vip.component.html',
  styleUrls: ['./add-vip.component.scss']
})
export class AddVipComponent implements OnInit {

  constructor(private activate:ActivatedRoute , private vipservice:VipContactsService, 
    private router:Router) { }

  names:''
  languages:any
  levels:any
  editId:any
  model = new VIP()
  
  ngOnInit(): void {
 
    console.log(this.activate.snapshot)
    this.editId = this.activate.snapshot.params.id;
    if (this.editId) {
      this.getVIPById();
    }
  }

  onSubmit(){

    if(this.editId){
     this.vipservice.editVipContacts(this.editId,this.model).subscribe(
       res=>{
      if (res["success"] == 1) {
        swal.fire({
          title: "Success",
          text: "VIP Updated Successfully",
          icon: "success",
        });
        this.router.navigateByUrl("/v2/support/vip-host");
      } else {
        swal.fire({
          title: "Error",
          text: res["error"][0],
          icon: "error",
        });
    }
     }, error => {
      swal.fire({
        title: "Error",
        text: error,
        icon: "error",
      });
    })
    }
    else{
      this.vipservice.addVipContacts(this.model).subscribe(res=>{
        if (res["success"] == 1) {
          swal.fire({
            title: "Success",
            text: "VIP added Successfully",
            icon: "success",
          });
          this.router.navigateByUrl("/v2/support/vip-host");
        } else {
          swal.fire({
            title: "Error",
            text: res["error"][0],
            icon: "error",
          });
      }
    }, error => {
      swal.fire({
        title: "Error",
        text: error,
        icon: "error",
      });
    })
  }
  }

  getVIPById(){

    this.vipservice.getVipContactsById(this.editId).subscribe(res=>{

      if(res){
        this.model=res.data
      }
    },
    error => {
      swal.fire({
        title: "Error",
        text: error,
        icon: "error",
      });
    })
  }

}
