import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FAQService } from 'src/app/service/support-admin/faq.service';
import { AddTerms } from '../../models/addterms';
import { faqData } from '../../models/faqdata';
import { Question } from '../../models/questionData';
import { termsData } from '../../models/termsdata';
declare var swal:any
@Component({
  selector: 'app-add-terms',
  templateUrl: './add-terms.component.html',
  styleUrls: ['./add-terms.component.scss']
})
export class AddTermsComponent implements OnInit {
  panelOpenState: boolean;

  data = new termsData();
  lang: string = "";
  name: string = "";

  languages = [
    { id: "en", name: "English" },
    { id: "ta", name: "TA" },
    { id: "fj", name: "FJ" },
  ];

  constructor(
    private faqservice: FAQService,
    private activate: ActivatedRoute,
    private router: Router
  ) {}

  screenNames = [];
  ngOnInit(): void {
    this.getScreens();
    this.lang = this.activate.snapshot.queryParams.language;
    this.name = this.activate.snapshot.queryParams.name;
    if (this.lang) {
      this.getTermById();
    }
  }

  getScreens() {
    this.faqservice.getScreenNames().subscribe((res) => {
      console.log(res.data);
      let data = res.data.filter(res=>{
        return res.is_deleted !=true
      })
      this.screenNames = data;
    });
  }
  addQuestion() {
    this.data.termsAndConditions.push(new AddTerms());
  }

  delete(id) {
    this.data.termsAndConditions.splice(id, 1);
  }

  submit() {
    this.data.termsAndConditions.map((res, index) => {
      res.priority = index + 1;
    });
    if (this.lang) {
      this.faqservice
        .getTermsById(this.lang, this.data, this.name)
        .subscribe((res) => {
          console.log(res["success"]);
          if (res["success"] == 1) {
            swal.fire({
              title: "Success",
              text: "Screen Updated Successfully",
              icon: "success",
            });
            this.router.navigateByUrl("/v2/product/content/faq/view-terms");
          } else {
            swal.fire({
              title: "Error",
              text: res["error"][0],
              icon: "error",
            });
          }
        }, error => {
          swal.fire({
            title: "Error",
            text: error,
            icon: "error",
          });
        });
    } else {
      this.faqservice.addTerms(this.data).subscribe((res) => {
        console.log(res["success"]);
        if (res["success"] == 1) {
          swal.fire({
            title: "Success",
            text: "Terms Added Successfully",
            icon: "success",
          });
          this.router.navigateByUrl("/v2/product/content/faq/view-terms");
        } else {
          swal.fire({
            title: "Error",
            text: res["error"][0],
            icon: "error",
          });
        }
      }, error => {
        swal.fire({
          title: "Error",
          text: error,
          icon: "error",
        });
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.data.termsAndConditions,
      event.previousIndex,
      event.currentIndex
    );
  }

  getTermById() {
    let data = JSON.parse(localStorage.getItem("terms"));
    this.faqservice
      .getTermsById(this.lang, data, this.name)
      .subscribe((res) => {
        console.log(res);
        if (res) {
          let data = {
            language: res.data.language,
            screen_name: res.data.screen_id,
            termsAndConditions: res.data.termsAndConditions,
          };
          this.data = data;
          this.data.screen_name = this.name;
        }
      }, error => {
        swal.fire({
          title: "Error",
          text: error,
          icon: "error",
        });
      });
  }
}
