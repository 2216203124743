import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
  role?: string
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [


  {
    id: 'navigation',
    title: 'Promotions (CRM)',
    type: 'group',
    icon: 'icon-navigation',
    role: 'Promotions',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/v2/promo/dashboard/default',
        icon: 'feather icon-home',
        classes: 'nav-item'
      },
    ]
  },


  //BONUS - 22-12-20
  {
    id: 'all-bonus',
    title: 'Bonuses',
    type: 'group',
    icon: 'feather icon-box',
    role: 'Bonus',
    children: [
      {
        id: 'campaign',
        title: 'Campaign',
        type: 'item',
        url: '/v2/promo/campaign',
        icon: 'feather icon-home',
        classes: 'nav-item'
      },
      {
        id: 'sign-up-bonus',
        title: 'Sign Up Bonus',
        type: 'item',
        url: '/v2/promo/signup-bonus',
        icon: 'feather icon-home',
        classes: 'nav-item'
      },
      {
        id: 'referrer-signup-bonus',
        title: 'Referrer SignUp Bonus',
        type: 'item',
        url: '/v2/promo/referrer-signup-bonus',
        icon: 'feather icon-home',
        classes: 'nav-item'
      },
      {
        id: 'purchase-bonus',
        title: 'Purchase Bonus',
        type: 'item',
        url: '/v2/promo/purchase-bonus',
        icon: 'feather icon-file-text',
        classes: 'nav-item'
      },
      {
        id: 'referrer-purchase-bonus',
        title: 'Referrer Purchase Bonus',
        type: 'item',
        url: '/v2/promo/referrer-purchase-bonus',
        icon: 'feather icon-home',
        classes: 'nav-item'
      },
      {
        id: 'reward-points-burn-bonus',
        title: 'Power coins Burn Bonus',
        type: 'item',
        url: '/v2/promo/power-coin-burn-bonus',
        icon: 'feather icon-home',
        classes: 'nav-item'
      },
      {
        id: 'login-bonus',
        title: 'Login Bonus',
        type: 'item',
        url: '/v2/promo/login-bonus',
        icon: 'feather icon-unlock',
        classes: 'nav-item'
      },
      {
        id: 'bonus-booster',
        title: 'Bonus Booster',
        type: 'item',
        url: '/v2/promo/bonus-booster',
        icon: 'feather icon-home',
        classes: 'nav-item'
      },
      // {
      //   id: 'vip-contacts',
      //   title: 'Vip Contacts',
      //   type: 'item',
      //   url: '/vip-contacts',
      //   icon: 'feather icon-user',
      //   classes: 'nav-item'
      // },
      // {
      //   id: 'challenge',
      //   title: 'Challenge',
      //   type: 'item',
      //   url: '/challenge',
      //   icon: 'feather icon-target',
      //   classes: 'nav-item'
      // },
      {
        id: 'get-active-bonus',
        title: 'Get Active Bonus',
        type: 'item',
        url: '/get-active-bonus',
        icon: 'feather icon-box'
      },
    ]
  },

  //Bet-config
  {
    id: 'bet-configuration',
    title: 'Bet Configuration',
    type: 'group',
    role: 'Bet',
    icon: 'feather icon-box',
    children: [
      {
        id: 'bet-config-rummy',
        title: 'Rummy',
        type: 'item',
        url: '/v2/product/config/bet/rummy',
        icon: 'feather icon-home',
        classes: 'nav-item'
      },
      {
        id: 'bet-config-callbreak',
        title: 'Callbreak',
        type: 'item',
        url: '/v2/product/config/bet/callbreak',
        icon: 'feather icon-home',
        classes: 'nav-item'
      },
      {
        id: 'bet-config-poker',
        title: 'Poker',
        type: 'item',
        url: '/v2/product/config/bet/poker',
        icon: 'feather icon-home',
        classes: 'nav-item'
      },
      {
        id: 'bet-config-hot-tables',
        title: 'Hot Tables',
        type: 'item',
        url: '/v2/product/config/bet/hot-tables',
        icon: 'feather icon-home',
        classes: 'nav-item'
      },
     
      // v2/product/config/bet/activity-transaction
    ]
  },
  {
    id: 'wallet-management',
    title: 'Wallet Management',
    type: 'group',
    role: 'Bet',
    icon: 'feather icon-box',
    children: [
      // {
      //   id: 'currency',
      //   title: 'Currency',
      //   type: 'item',
      //   url: '/v2/product/config/bet/currency',
      //   icon: 'feather icon-home',
      //   classes: 'nav-item'
      // },
        {
        id: 'organisation',
        title: 'Organisations',
        type: 'item',
        url: '/v2/product/config/bet/organisation',
        icon: 'feather icon-home',
        classes: 'nav-item'
      },
      {
        id: 'application-wallet',
        title: 'Application Wallet',
        type: 'item',
        url: '/v2/product/config/bet/application-wallet',
        icon: 'feather icon-home',
        classes: 'nav-item'
      },
      
      // {
      //   id: 'bank Templates',
      //   title: 'Bank Templates',
      //   type: 'item',
      //   url: '/v2/product/config/bet/organisation-wallet',
      //   icon: 'feather icon-home',
      //   classes: 'nav-item'
      // },
      
      // {
      //   id: 'organisation-application',
      //   title: 'Organisation Application',
      //   type: 'item',
      //   url: '/v2/product/config/bet/application',
      //   icon: 'feather icon-home',
      //   classes: 'nav-item'
      // },
      // {
      //   id: 'currency-exchange',
      //   title: 'Exchange Currency',
      //   type: 'item',
      //   url: '/v2/product/config/bet/currency-exchange',
      //   icon: 'feather icon-home',
      //   classes: 'nav-item'
      // },
      // {
      //   id: 'user-wallet',
      //   title: 'User-wallet',
      //   type: 'item',
      //   url: '/v2/product/config/bet/user-wallet',
      //   icon: 'feather icon-home',
      //   classes: 'nav-item'
      // },
      // {
      //   id: 'user-wallet-config',
      //   title: 'User-wallet-config',
      //   type: 'item',
      //   url: '/v2/product/config/bet/user-wallet-config',
      //   icon: 'feather icon-home',
      //   classes: 'nav-item'
      // },
      // {
      //   id: 'activity-transaction',
      //   title: 'Activity Transaction',
      //   type: 'item',
      //   url: '/v2/product/config/bet/activity-transaction',
      //   icon: 'feather icon-home',
      //   classes: 'nav-item'
      // },
    ]
  },


  //Product (Game Management)
  {
    id: 'product',
    title: 'Product (Game Management)',
    type: 'group',
    icon: 'icon-ui',
    role: 'Product',
    children: [
      // {
      //   id: 'product-dashboard',
      //   title: 'Dashboard',
      //   type: 'item',
      //   url: '/dashboard/default',
      //   icon: 'feather icon-home',
      //   classes: 'nav-item',
      // },
      //BET CONFIGURATION - 08-02-2021

      {
        id: 'fraudusers',
        title: 'Fraud Users',
        type: 'item',
        url: '/v2/product/fraudusers',
        classes: 'nav-item',
        icon: 'feather icon-box'
      },
      {
        id: 'tournament',
        title: 'Tournaments',
        type: 'item',
        url: '/v2/product/tournament/rummy',
        icon: 'feather icon-home',
        classes: 'nav-item'
      },

      {
        id: 'game-versions-tracks',
        title: 'App Versions Management',
        type: 'item',
        url: '/v2/product/release/android-rmg',
        icon: 'feather icon-search',
        classes: 'nav-item',

      },
      {
        id: 'faq',
        title: 'Product Content',
        type: 'item',
        url: '/v2/product/content/faq',
        classes: 'nav-item',
        icon: 'feather icon-box'
      },
      {
        id: 'banners',
        title: 'Intro Banners',
        type: 'item',
        url: '/v2/product/content/banner',
        icon: 'feather icon-image',
        classes: 'nav-item'
      },
      {
        id: 'powercoins',
        title: 'Powercoins Config',
        type: 'item',
        url: '/v2/product/config/power-coin',
        classes: 'nav-item',
        icon: 'feather icon-box'
      },
      {
        id: 'loyalty',
        title: 'Loyality Levels config',
        type: 'item',
        url: '/v2/product/config/loyalty',
        icon: 'feather icon-image',
        classes: 'nav-item'
      },
      {
        id: 'loyalty',
        title: 'Loyality Points config',
        type: 'item',
        url: '/v2/product/config/loyalty-Points',
        icon: 'feather icon-image',
        classes: 'nav-item'
      },
    ],
  },


  //  SUPPORT ADMIN - 11-12-2020 - START
  {
    id: 'support-admin',
    title: 'Support (Customer Support)',
    type: 'group',
    icon: 'icon-ui',
    role: 'Support',
    children: [
      // {
      //   id: 'support-dashboard',
      //   title: 'Dashboard',
      //   type: 'item',
      //   url: '/dashboard/default',
      //   icon: 'feather icon-home',
      //   classes: 'nav-item',
      // },
      {
        id: 'game-users',
        title: 'Search User',
        type: 'item',
        url: '/v2/support/user/search',
        icon: 'feather icon-search',
        classes: 'nav-item'
      },

      {
        id: 'game-user-details',
        title: 'User KYC Details',
        type: 'item',
        url: '/v2/support/user/kyc',
        icon: 'feather icon-user',
        classes: 'nav-item'
      },

      {
        id: 'kyc-approval-list',
        title: 'KYC Approval List',
        type: 'item',
        url: '/v2/support/kyc/pan',
        icon: 'feather icon-credit-card',
        classes: 'nav-item'
      },
      {
        id: 'vip',
        title: 'VIP',
        type: 'item',
        url: '/v2/support/vip-host',
        classes: 'nav-item',
        icon: 'feather icon-box'
      },
      {
        id: 'user-wallet-track',
        title: 'User Wallet Track',
        type: 'item',
        url: '/v2/support/user/wallet',
        classes: 'nav-item',
        icon: 'feather icon-box'
      },

      {
        id: 'user-payments-track',
        title: 'User Payments Track',
        type: 'item',
        url: '/v2/support/user/payment',
        classes: 'nav-item',
        icon: 'feather icon-book'
      },
      {
        id: 'report-issue',
        title: 'Report Issues',
        type: 'item',
        url: '/v2/support/report/issues',
        icon: 'feather icon-box',
        classes: 'nav-item'
      },
      // {
      //   id: 'faq-add',
      //   title: 'Add FAQ',
      //   type: 'item',
      //   url: '/faq/add',
      //   classes: 'nav-item',
      //   icon: 'feather icon-box'
      // },

    ]
  },


  //   Finance (FinOps)
  {
    id: 'finance',
    title: 'Finance (FinOps)',
    type: 'group',
    icon: 'icon-ui',
    role: 'Finance',
    children: [
      // {
      //   id: 'product-dashboard',
      //   title: 'Dashboard',
      //   type: 'item',
      //   url: '/dashboard/default',
      //   icon: 'feather icon-home',
      //   classes: 'nav-item',
      // },
      {
        id: 'user-withdraw-request-tracks',
        title: 'Withdraw Requests',
        type: 'item',
        url: '/v2/ops/withdraw-processing',
        classes: 'nav-item',
        icon: 'feather icon-box'
      },
      {
        id: 'wallet-reversal',
        title: 'Game refund flow',
        type: 'item',
        url: '/v2/ops/wallet/wallet-reversal',
        classes: 'nav-item',
        icon: 'feather icon-box'
      },
      {
        id: 'compensation',
        title: 'Manual Compensation',
        type: 'item',
        url: '/v2/ops/manual-bonus',
        classes: 'nav-item',
        icon: 'feather icon-box'
      },
      {
        id: 'compensation',
        title: 'Game Compensation',
        type: 'item',
        url: '/v2/ops/manual-bonus-chips',
        classes: 'nav-item',
        icon: 'feather icon-box'
      },
      {
        id: 'wwr',
        title: 'Wallet Withdraw Requests',
        type: 'item',
        url: '/v2/ops/wallet-withdraw',
        classes: 'nav-item',
        icon: 'feather icon-box'
      },
      {
        id: 'ban-states',
        title: 'Banned States',
        type: 'item',
        url: '/v2/ops/ban-states',
        classes: 'nav-item',
        icon: 'feather icon-box'
      },
      {
        id: 'wallet-signifiers',
        title: 'Wallet Signifiers',
        type: 'item',
        url: '/v2/ops/wallet/signifiers',
        classes: 'nav-item',
        icon: 'feather icon-box'
      },
    
    ]
  },


  // Devops
  {
    id: 'devops',
    title: 'Devops ',
    type: 'group',
    icon: 'icon-ui',
    role: 'Devops',
    children: [
      // {
      //   id: 'product-dashboard',
      //   title: 'Dashboard',
      //   type: 'item',
      //   url: '/dashboard/default',
      //   icon: 'feather icon-home',
      //   classes: 'nav-item',
      // },
      {
        id: 'game-server',
        title: 'Game Server',
        type: 'item',
        url: '/v2/devops/config/game-server',
        classes: 'nav-item',
        icon: 'feather icon-box'
      },
    ]
  }
];

@Injectable()
export class NavigationItem {
  get() {
    return NavigationItems;
  }
}
