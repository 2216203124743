import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AdminUserConfigService { 

  API_URL: string = environment.APIExpressEndpoint;
  API_BOOT_CONFIG_URL: string = environment.APIBootConfigPoint;

  constructor(
    private http: HttpClient,
  ) { } 

  getAdminUserConfigList(param): Observable<any> {
    return this.http.get(`${this.API_URL}/admin_users/`, { params: param });
  }

  renewtoken(data){
    return this.http.post(`${this.API_URL}/renewToken`, data)
  }
  addAdminUserConfig(data): Observable<any> {
    return this.http.post(`${this.API_URL}/admin_users/`, data);
  }

  editAdminUserConfig(id,data): Observable<any> {
  return this.http.put(`${this.API_URL}/admin_users/${id}/`, data);
  }

  lastloginAdminUserConfig(id): Observable<any> {
    return this.http.put(`${this.API_URL}/last_login_admin_users/${id}/`, {id});
  }

  // cancelAdminUserConfig(id,data): Observable<any> {
  //   return this.http.put(`${this.API_URL}/cancel-admin_users/${id}/`, data);
  // }
  
  // acceptAdminUserConfig(id,data): Observable<any> {
  //   return this.http.put(`${this.API_URL}/accept-admin_users/${id}/`, data);
  // }

  getAdminUserConfigById(id, param = null): Observable<any> {
    return this.http.get(`${this.API_URL}/admin_users/${id}/`, { params: param });
  }

  deleteAdminUserConfig(id): Observable<any> {
    return this.http.delete(`${this.API_URL}/admin_users/${id}/`);
  }
  
  getToken(email):Observable<any>{
    return this.http.put(`${this.API_URL}/authenticate/${email}/`,null)
  }
}
