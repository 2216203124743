<div class="row mb-4">
  <div class="col-md-12 mb-4">
    <div class="card text-left">

      <div class="card-body">
        <!--<h4 class="card-title mb-3">Zero configuration</h4>
              <p>DataTables has most features enabled by default, so all you need to do to use it with your own ables is to call the construction function: $().DataTable();.</p>-->
        <div class="table-responsive1">
          <div fxLayout fxLayoutAlign="center right">
            <!-- <mat-form-field fxFlex="40%">
                    <input matInput type="date" #c1 (change)="changeStartDate($event.target.value)" placeholder="From Date">
                  </mat-form-field> 
                  
                  <mat-form-field fxFlex="40%">
                    <input matInput type="date" #c2 (change)="changeEndDate($event.target.value)" placeholder="To Date">
                  </mat-form-field> -->

            <!-- <mat-form-field appearance="fill">      
                    <mat-label>Enter a date range</mat-label>      
                    <mat-date-range-input [rangePicker]="picker">      
                      <input matStartDate placeholder="Start date">      
                      <input matEndDate placeholder="End date" (dateChange)="changeSearchText($event.target.value)">      
                    </mat-date-range-input>      
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>      
                    <mat-date-range-picker #picker></mat-date-range-picker>      
                  </mat-form-field>   -->

            <mat-form-field fxFlex="40%">
              <input matInput type="text" #c3 (keyup)="searchbyID($event.target.value)" placeholder="Search by ID">
            </mat-form-field>


            <button style="margin-left: 5px;display: none;" color="warn" mat-raised-button
              (click)="clearFilters();  c3.value='';" class="three">Reset</button>


          </div>
          <div *ngIf="loadingState==false;else loadingData">
            <mat-table #table [dataSource]="dataSource" matSort class="mat-elevation-z81" hiddenColumns="[4]">

              <ng-container matColumnDef="#">
                <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
                <mat-cell *matCellDef="let element; index as i"> {{paginator.pageSize * paginator.pageIndex + i + 1}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="id">
                <mat-header-cell  *matHeaderCellDef>User ID </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Opening_Bal">
                <mat-header-cell  *matHeaderCellDef> Opening Bal</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{(element.Opening_Bal).toFixed(2)}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Closing_Bal">
                <mat-header-cell  *matHeaderCellDef> Closing Bal </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{(element.Closing_Bal).toFixed(2)}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Trnx_Amount">
                <mat-header-cell  *matHeaderCellDef>Amount </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.Trnx_Amount}} </mat-cell>
              </ng-container>


              <!-- <ng-container matColumnDef="userId">
                        <mat-header-cell  *matHeaderCellDef> User ID </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.userId}} </mat-cell>
                      </ng-container> -->

              <ng-container matColumnDef="Trnx_Type_Txt" style="padding-left: 5px;">
                <mat-header-cell  *matHeaderCellDef> Transaction Type</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.Trnx_Type_Txt}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="cd">
                <mat-header-cell  *matHeaderCellDef> Date</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.cd | date: "MMM d, y, h:mm a" }} </mat-cell>
              </ng-container>


              <ng-container matColumnDef="gt">
                <mat-header-cell  *matHeaderCellDef> Game Type</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.gt }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="bv">
                <mat-header-cell  *matHeaderCellDef> Bet Value</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.bv }}
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

          </div>
          <mat-paginator [pageSizeOptions]="[10,30,50,100,200]" showFirstLastButtons></mat-paginator>
        
        </div>
        <ng-template #loadingData>
          <div id="waitforData">
            <div class="spinner spinner-primary mr-3"></div>
            <h4 class="example-h2">Loading ,,,,</h4>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<!-- end of col -->