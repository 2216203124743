import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/distinctUntilChanged';
import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  // for storing current user details
  public currentUserSubject = new BehaviorSubject(null);
  public currentUser = this.currentUserSubject.asObservable().distinctUntilChanged();

  // for checking user is authenticated or not
  public isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private jwtService: JwtService,
  ) { }

  saveToken(token, user_details) {
    this.jwtService.saveToken(token, user_details);
  }

  setAuth(user, user_details) {
    this.saveToken(user['authToken'], user_details);
    this.updateAuth(user);
  }

  updateAuth(data) {
    console.log("updateAuth : ", data);

    this.currentUserSubject.next(data);
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    this.jwtService.destroyToken();
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    this.currentUserSubject.next(null);
  }
}
