import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { UserWalletTrackListComponent } from './user-wallet-track-list.component';
import { UserWalletTrackService } from 'src/app/service/support-admin/user-wallet-track.service';

import { MaterialModule } from '../../material.module';

const routes: Routes = [
  {
    path: '',
    component: UserWalletTrackListComponent,
  },
];

@NgModule({
  declarations: [UserWalletTrackListComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    MaterialModule
  ], providers: [UserWalletTrackService]
})

export class UserWalletTrackListModule { }
